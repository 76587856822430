import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import styles from './FilterRow.module.css'
import { useTranslation } from 'react-i18next'
import { PTextFieldWrapper } from '@porsche-design-system/components-react'

const VinSearchInput = (props: {
  onChange: Function
  vinSearchInput: string
}) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState(props.vinSearchInput)

  const triggerGetRequest = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      props.onChange(inputValue)
    },
    // eslint-disable-next-line
    [props, inputValue]
  )

  useEffect(() => {
    if (inputValue === '') {
      props.onChange(inputValue)
    }
    // eslint-disable-next-line
  }, [inputValue])

  return (
    <form
      action="#"
      className={styles.vinSearchInput}
      onSubmit={triggerGetRequest}
    >
      <PTextFieldWrapper actionLoading={false} className={styles.selectFilter}>
        <input
          type="search"
          name="VINSearchInput"
          aria-label="VIN search input"
          placeholder={t('sellerView.vinSearchInput')!!}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </PTextFieldWrapper>
    </form>
  )
}

export default VinSearchInput
