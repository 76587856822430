import React, {createContext} from 'react'
import {
  PorscheDesignSystemProvider,
  PText,
  PToast,
} from '@porsche-design-system/components-react'
import {TabsBar} from './Components/TabsBar/TabsBar'
import usePPNAuthentication from './Hooks/usePPNAuthentication'
import Header from './Components/Header/Header'
import styles from './Components/TabsBar/TabsBar.module.css'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import DetailsPage from './Components/Content/DetailsPage/DetailsPage'
import AuctionPage from './Components/Content/AuctionPage/AuctionPage'
import IncomingVehiclesContent from './Components/Content/IncomingTab/IncomingVehiclesContent'
import ReturnedVehiclesContent from './Components/Content/ReturnedTab/ReturnedVehiclesContent'
import SoldVehiclesContent from './Components/Content/SoldTab/SoldVehiclesContent'
import {useTranslation} from 'react-i18next'
import LiveSaleTab from './Components/Content/LiveSaleTab/LiveSaleTab'
import PreviewTab from './Components/Content/PreviewTab/PreviewTab'
import ReadyForSaleTab from './Components/Content/ReadyForSaleTab/ReadyForSaleTab'
import Custom404Page from './Components/Content/Custom404Page/Custom404Page'
import loadEnvironment from "./loadEnvironment";
import AnalyticsTab from './Components/Content/AnalyticsTab/AnalyticsTab'

const environment = loadEnvironment()

export const UserInfo = createContext({
  isSeller: false,
  isDealer: false,
  isAdmin: false,
  userId: '',
})

const App = () => {
  const {t} = useTranslation()

  const {isAuthorized, displayName, systemRoles, userId} =
    usePPNAuthentication()

  if (!isAuthorized) {
    return <div></div>
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<TabsBar/>} errorElement={<Custom404Page/>}>
        {systemRoles.isSeller ? (
          <>
            {environment.FEATURE_TOGGLES.SHOW_INCOMING_VEHICLES_TAB && (
              <Route
                index
                element={
                  <div className={styles.whiteBackground}>
                    <PText
                      className={styles.title}
                      size="large"
                      weight="semi-bold"
                    >
                      {t('sellerView.incomingCarsTabTitle')}
                    </PText>
                    <IncomingVehiclesContent pageSize={20}/>
                  </div>
                }
              />
            )}
            <Route
              index
              element={
                <div className={styles.whiteBackground}>
                  <PText
                    className={styles.title}
                    size="large"
                    weight="semi-bold"
                  >
                    {t('sellerView.returnedCarsTabTitle')}
                  </PText>
                  <ReturnedVehiclesContent pageSize={20}
                                           blockedForSalesFilter={false}
                  />
                </div>
              }
            />
            <Route
              path="/returned"
              element={
                <div className={styles.whiteBackground}>
                  <PText
                    className={styles.title}
                    size="large"
                    weight="semi-bold"
                  >
                    {t('sellerView.returnedCarsTabTitle')}
                  </PText>
                  <ReturnedVehiclesContent
                    pageSize={20}
                    blockedForSalesFilter={false}
                  />
                </div>
              }
            />
            <Route
              path="/ready"
              element={
                <div className={styles.whiteBackground}>
                  <PText
                    className={styles.title}
                    size="large"
                    weight="semi-bold"
                  >
                    {t('sellerView.readyForSaleTabTitle')}
                  </PText>
                  <ReadyForSaleTab/>
                </div>
              }
            />
            <Route
              path="/sale"
              element={
                <div className={styles.whiteBackground}>
                  <PText
                    className={styles.title}
                    size="large"
                    weight="semi-bold"
                  >
                    {t('sharedView.liveSaleTabTitle')}
                  </PText>
                  <LiveSaleTab/>
                </div>
              }
            />
            <Route
              path="/sold"
              element={
                <div className={styles.whiteBackground}>
                  <PText
                    className={styles.title}
                    size="large"
                    weight="semi-bold"
                  >
                    {t('sellerView.soldCarsTabTitle')}
                  </PText>
                  <SoldVehiclesContent pageSize={20}/>
                </div>
              }
            />
            <Route
              path="/blocked"
              element={
                <div className={styles.whiteBackground}>
                  <PText
                    className={styles.title}
                    size="large"
                    weight="semi-bold"
                  >
                    {t('sellerView.blockedCarsTabTitle')}
                  </PText>
                  <ReturnedVehiclesContent
                    pageSize={20}
                    blockedForSalesFilter={true}
                  />
                </div>
              }
            />
            {systemRoles.isAdmin && (
              <Route path="/analytics" element={<AnalyticsTab/>}/>
            )}
            <Route path="/details/:vin" element={<DetailsPage/>}/>
            <Route path="/details/:vin/auction" element={<AuctionPage/>}/>
          </>
        ) : (
          systemRoles.isDealer && (
            <>
              <Route
                path="/preview"
                element={
                  <div className={styles.whiteBackground}>
                    <PText
                      className={styles.title}
                      size="large"
                      weight="semi-bold"
                    >
                      {t('dealerView.previewTabTitle')}
                    </PText>
                    <PreviewTab/>
                  </div>
                }
              />
              <Route
                index
                element={
                  <div className={styles.whiteBackground}>
                    <PText
                      className={styles.title}
                      size="large"
                      weight="semi-bold"
                    >
                      {t('sharedView.liveSaleTabTitle')}
                    </PText>
                    <LiveSaleTab/>
                  </div>
                }
              />
              <Route
                path="/sold"
                element={
                  <div className={styles.whiteBackground}>
                    <PText
                      className={styles.title}
                      size="large"
                      weight="semi-bold"
                    >
                      {t('dealerView.purchasedCarsTabTitle')}
                    </PText>
                    <SoldVehiclesContent pageSize={20}/>
                  </div>
                }
              />
              <Route path="/details/:vin" element={<DetailsPage/>}/>
            </>
          )
        )}
      </Route>
    )
  )

  return (
    <React.StrictMode>
      <PorscheDesignSystemProvider>
        <UserInfo.Provider value={{...systemRoles, userId}}>
          <Header userName={displayName}/>
          <div className={styles.fullHeightContent}>
            <RouterProvider router={router}/>
          </div>
          <PToast/>
        </UserInfo.Provider>
      </PorscheDesignSystemProvider>
    </React.StrictMode>
  )
}

export default App
