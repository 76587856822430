import { useEffect, useState } from 'react'
import { callBackendAPI } from '../api'
import loadEnvironment from '../loadEnvironment'
import { PorscheCenter } from '../Models/PorscheCenter'

const environment = loadEnvironment()

export default function usePorscheCenter(
  userId: string | null,
  isDealer: boolean
): PorscheCenter[] | null {
  const [porscheCenter, setPorscheCenter] = useState([] as PorscheCenter[])

  useEffect(() => {
    let isMounted = true

    const fetchPorscheCenters = async () => {
      const response = await callBackendAPI(
        `${environment.B2B_BACKEND_BASE_URL}/api/ppn-user`
      )

      if (isMounted) {
        setPorscheCenter(response.data as PorscheCenter[])
      }
    }

    if (isDealer) {
      fetchPorscheCenters()
    }

    return () => {
      isMounted = false
    }
  }, [userId, isDealer])

  return porscheCenter
}
