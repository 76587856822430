import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as germanTranslation from './locale/de.json'
import * as englishTranslation from './locale/en.json'

const environment = process.env.REACT_APP_ENV

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v4',
  resources: {
    de_de: {
      translation: germanTranslation,
    },
    en_gb: {
      translation: englishTranslation,
    },
    en_us: {
      translation: englishTranslation,
    },
  },
  fallbackLng: environment === 'test' ? 'de_de' : 'en_us',
})

export default i18n
