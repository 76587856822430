import { useEffect, useState } from 'react'
import { Vehicle } from '../Models/Vehicle'
import { callBackendAPI, createVehicleURL } from '../api'

export default function useVehicles(
  modelFilter: string,
  derivativeFilter: string[],
  primaryStatusFilter: string[],
  secondaryStatusFilter: string,
  blockedForSalesFilter: Boolean,
  futureAuctionsFilter: Boolean,
  ignoreSoldCars: Boolean,
  pageIndex: number,
  pageSize: number = 20,
  vinSearchInput?: string,
  auctionBundleStatus?: string,
  atDate?: string
) {
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [totalVehicles, setTotalVehicles] = useState(0)

  useEffect(() => {
    let isMounted = true

    const fetchVehicles = async () => {
      const vehicleURL = createVehicleURL(modelFilter, derivativeFilter, primaryStatusFilter, secondaryStatusFilter, blockedForSalesFilter, futureAuctionsFilter, ignoreSoldCars, pageIndex, pageSize, vinSearchInput, auctionBundleStatus, atDate)
      const response = await callBackendAPI(vehicleURL)

      if (isMounted) {
        setVehicles(response.data.vehicles)
        setTotalVehicles(response.data.total)
      }
    }

    fetchVehicles()

    return () => {
      isMounted = false
    }
  }, [
    modelFilter,
    derivativeFilter,
    primaryStatusFilter,
    secondaryStatusFilter,
    blockedForSalesFilter,
    futureAuctionsFilter,
    ignoreSoldCars,
    pageIndex,
    pageSize,
    vinSearchInput,
    auctionBundleStatus,
    atDate
  ])

  return { vehicles, totalVehicles }
}
