import {
  Body,
  ConsumptionEmission,
  Engine,
  Performance,
  PowerItem,
  Range,
  TechnicalData,
  TranslatedKeyValueUnit,
} from '../../../Models/VehicleDetails'
import { PDivider, PHeading } from '@porsche-design-system/components-react'
import styles from './DetailsPage.module.css'
import { createDetailRow } from './ModelInformationBlock'
import { useTranslation } from 'react-i18next'

export const TechnicalDataBlock = (props: {
  technicalData?: TechnicalData
}) => {
  const { t } = useTranslation()

  const flattenObject = (o: object): Array<any> => {
    let flattenedObject: any[] = []
    let values = Object.values(o)
    for (let i = 0; i <= values.length; i++) {
      const val = values[i]
      if (val !== null && typeof val === 'object') {
        flattenedObject = flattenedObject.concat(flattenObject(val))
      } else {
        flattenedObject = flattenedObject.concat(val)
      }
    }

    return flattenedObject
  }
  const isSectionEmpty = (
    section:
      | Engine
      | Body
      | Performance
      | ConsumptionEmission
      | undefined
      | null
  ) => {
    let isEmpty = true
    const flattenedObject = flattenObject(section || {})
    for (let i = 0; i <= flattenedObject.length; i++) {
      const value = flattenedObject[i]
      if (value !== undefined && value !== null) {
        isEmpty = false
        break
      }
    }
    return isEmpty
  }
  const getEngineSection = () => {
    return (
      <div className={styles.technicalDataSection}>
        <PHeading tag="h5" size="medium">
          {t('sharedView.vehicleEngine')}
        </PHeading>
        <PDivider />
        {createKeyValueUnitRow(engine?.engine_layout)}
        {createKeyValueUnitRow(engine?.displacement)}
        {createKeyValueUnitRow(engine?.fuel_type)}
        {createKeyValueUnitRow(engine?.number_of_cylinders)}
        {createKeyValueUnitRowPower(engine?.power?.hybrid?.combustion_engine)}
        {createKeyValueUnitRowPower(
          engine?.power?.electric?.overboost_launch_control_up_to
        )}
        {createKeyValueUnitRowPower(engine?.power?.electric?.up_to)}
        {createKeyValueUnitRowPower(engine?.power?.combustion)}
        {createKeyValueUnitRow(engine?.max_torque?.combustion_engine)}
        {createKeyValueUnitRow(
          engine?.max_torque?.combustion_engine_with_overboost
        )}
        {createKeyValueUnitRow(engine?.max_torque?.electric_engine)}
        {createKeyValueUnitRow(engine?.max_torque?.launch_control)}
        {createKeyValueUnitRow(engine?.max_torque?.combined)}
        {createKeyValueUnitRowRange(
          engine?.max_power_at_rpm?.combustion_engine
        )}
        {createKeyValueUnitRowRange(engine?.max_power_at_rpm?.electric_engine)}
        {createKeyValueUnitRowRange(engine?.max_power_at_rpm?.combined)}
        {createKeyValueUnitRowRange(
          engine?.max_torque_at_rpm?.combustion_engine
        )}
        {createKeyValueUnitRowRange(engine?.max_torque_at_rpm?.electric_engine)}
        {createKeyValueUnitRowRange(engine?.max_torque_at_rpm?.combined)}
      </div>
    )
  }

  const getPerformanceSection = () => {
    return (
      <div className={styles.technicalDataSection}>
        <PHeading tag="h5" size="medium">
          Performance
        </PHeading>
        <PDivider />
        {createKeyValueUnitRow(performance?.acceleration?.from_0_to_100_kmh)}
        {createKeyValueUnitRow(performance?.acceleration?.from_0_to_160_kmh)}
        {createKeyValueUnitRow(
          performance?.acceleration?.from_80_to_120_kmh_in_gear
        )}
        {createKeyValueUnitRow(performance?.top_speed)}
      </div>
    )
  }

  const getBodySection = () => {
    return (
      <div className={styles.technicalDataSection}>
        <PHeading tag="h5" size="medium">
          {t('sharedView.vehicleBodyType')}
        </PHeading>
        <PDivider />
        {createKeyValueUnitRow(body?.length)}
        {createKeyValueUnitRow(body?.width_without_mirrors)}
        {createKeyValueUnitRow(body?.height)}
        {createKeyValueUnitRow(body?.wheel_base)}
        {createKeyValueUnitRow(body?.drag_coefficient)}
        {createKeyValueUnitRow(body?.unladen_weight?.din)}
        {createKeyValueUnitRow(body?.unladen_weight?.ec)}
        {createKeyValueUnitRow(body?.permissible_gross_weight)}
      </div>
    )
  }
  const getConsumptionEmissionSection = () => {
    return (
      <div className={styles.technicalDataSection}>
        <PHeading tag="h5" size="medium">
          {t('sharedView.vehicleConsumption')}
        </PHeading>
        <PDivider />
        {createKeyValueUnitRow(consumption_emission?.fuel_consumption?.urban)}
        {createKeyValueUnitRow(
          consumption_emission?.fuel_consumption?.extra_urban
        )}
        {createKeyValueUnitRow(
          consumption_emission?.fuel_consumption?.combined
        )}
        {createKeyValueUnitRow(
          consumption_emission?.electricity_consumption?.combined
        )}
        {createKeyValueUnitRow(consumption_emission?.co2_emissions)}
      </div>
    )
  }

  const createKeyValueUnitRowRange = (range?: Range) => {
    const { from, to } = range || ({} as Range)
    const key = from?.key || to?.key
    const value = `${from?.value} - ${to?.value}`
    const unit = from?.unit || to?.unit
    return createKeyValueUnitRow({ key: key, value: value, unit: unit })
  }
  const createKeyValueUnitRowPower = (powerItem?: PowerItem) => {
    const { kwt, n12 } = powerItem || ({} as PowerItem)
    let key = null
    let valueUnit = null
    if (kwt?.key && kwt?.value && kwt?.unit) {
      key = kwt.key
      valueUnit = `${kwt.value} ${kwt.unit}`
    }
    if (n12?.key && n12?.value && n12?.unit) {
      key = n12.key
      if (valueUnit) valueUnit += '/'
      valueUnit += `${n12.value} ${n12.unit}`
    }
    if (!key || !valueUnit) return null
    return createDetailRow(key, valueUnit)
  }
  const createKeyValueUnitRow = (keyValueUnit?: TranslatedKeyValueUnit) => {
    const { key, value, unit } = keyValueUnit || ({} as TranslatedKeyValueUnit)
    if (key && value) {
      let newValue = value
      if (unit) {
        newValue = `${value} ${unit}`
      }
      return createDetailRow(key, newValue)
    }
  }

  const { engine, performance, body, consumption_emission } =
    props.technicalData || ({} as TechnicalData)

  return (
    <div className={styles.detailBlock}>
      <PHeading className={styles.sectionHeadlines} tag="h3" size="large">
        {t('sharedView.technicalDataBlockTitle')}
      </PHeading>

      <div className={styles.equipmentCategoryWrapper}>
        {isSectionEmpty(engine) ? null : getEngineSection()}
        {isSectionEmpty(performance) ? null : getPerformanceSection()}
        {isSectionEmpty(body) ? null : getBodySection()}
        {isSectionEmpty(consumption_emission)
          ? null
          : getConsumptionEmissionSection()}
      </div>
    </div>
  )
}
