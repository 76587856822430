import React, {useEffect, useState} from 'react'
import {
  PButton,
  PButtonGroup,
  PIcon,
  PInlineNotification,
  PModal,
  PScroller,
  PText,
  PTextareaWrapper,
  PTextFieldWrapper,
  useToastManager,
} from '@porsche-design-system/components-react'
import { Vehicle } from '../../../Models/Vehicle'

import styles from '../ReturnedTab/PackageModal.module.css'
import { NumericFormat } from 'react-number-format'
import { priceFormatting } from '../../../helpers/helperFunctions'
import { useTranslation } from 'react-i18next'
import { callBackendAPI } from '../../../api'
import {
  startDateIsNotOnWeekend,
  validStartDate,
} from '../../../helpers/formValidations'
import loadEnvironment from '../../../loadEnvironment'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import {ErrorSerializer} from "../../../Models/ErrorSerializer";

const environment = loadEnvironment()

const PackageModal = (props: {
  selectedVehicles: Vehicle[]
  isOpen: boolean
  onDismiss: Function
}) => {
  const [startDate, setStartDate] = useState('')
  const [notes, setNotes] = useState('')
  let navigate = useNavigate()
  const { t } = useTranslation()
  const { addMessage } = useToastManager()
  const [errorHeading, setErrorHeading] = useState<String | null>(null)
  const [errorDescription, setErrorDescription] = useState<String | null>(null)
  const [iconsPresentNotificationHidden, setIconsPresentNotificationHidden] = useState(false)

  const [packageInitialPrice, setPackageInitialPrice] = useState(0)
  useEffect(() => {
    setPackageInitialPrice(props.selectedVehicles
      .map((vehicle) => Number(vehicle.initial_price))
      .reduce((a, b) => a + b))
  }, [props.selectedVehicles])

  const createPackage = async () => {
    callBackendAPI(`${environment.B2B_BACKEND_BASE_URL}/api/auctions`, 'POST', {
      auction: {
        initial_price: packageInitialPrice,
        start_date: `${startDate}T09:00:00Z`,
        notes,
        winner_notified: false,
        vins: props.selectedVehicles.map((v) => v.vin),
      },
      vehicles: props.selectedVehicles,
    })
      .then((response) => {
        if (response.status === 201) {
          localStorage.removeItem('bundleVehicles')
          navigate('/ready')
          addMessage({
            text: t('sellerView.auctionSuccessfullySaved'),
            state: 'success',
          })
        }
      })
      .catch((error: unknown) => {
        const axiosErrorResponse = (error as AxiosError<ErrorSerializer>).response
        const translationKey = axiosErrorResponse?.data.translationKey

        setErrorHeading(t(`exceptions.${translationKey || 'unexpectedError'}`))

        if (!translationKey) {
          setErrorDescription(
            `Trace ID: ${axiosErrorResponse?.headers['x-trace-id']}`
          )
        }
      })
  }

  return (
    <PModal
      open={props.isOpen}
      heading={t('sellerView.createOffer')}
      onDismiss={() => props.onDismiss()}
      aria={{ 'aria-label': 'Some Heading' }}
    >
      {errorHeading && (
        <div className={styles.modalError}>
          <PInlineNotification
            state="error"
            heading={errorHeading.toString()}
            description={errorDescription?.toString()}
            onDismiss={() => {
              setErrorHeading(null)
              setErrorDescription(null)
            }}
          ></PInlineNotification>
        </div>
      )}
      {props.selectedVehicles.find((v) => v.showroom || v.reserved || v.crash) && !iconsPresentNotificationHidden && (
        <PInlineNotification state="info" style={{marginBottom: '15px'}} onDismiss={() => setIconsPresentNotificationHidden(true)} >
          {t('sellerView.iconsPresentNotification', {icons: [
            props.selectedVehicles.find((v) => v.showroom) ? t('sellerView.showroom') : '',
            props.selectedVehicles.find((v) => v.reserved) ? t('sellerView.reserved.label') : '',
            props.selectedVehicles.find((v) => v.crash) ? t('sellerView.crash') : '',
            ].filter((v) => v)})}
        </PInlineNotification>
      )}
      <PText>
        {t('sellerView.vehicleSelected', {
          count: props.selectedVehicles.length,
        })}
      </PText>
      <div className={styles.packageModalContainer}>
        <PScroller>
          {props.selectedVehicles.map((vehicle, i) => (
            <div key={i} className={styles.packageModalItem}>
              <div>
                {vehicle.showroom ? (<PIcon name="home" size="small"></PIcon>) : (<></>)}
                {vehicle.reserved ? (<PIcon name="user" size="small"></PIcon>) : (<></>)}
                {vehicle.crash ? (<PIcon name="wrenches" size="small"></PIcon>) : (<></>)}
                {vehicle.extra_tyres ? (<PIcon name="snowflake" size="small"></PIcon>) : (<></>)}
              </div>
              <img
                className={styles.packageModalImage}
                src={vehicle.preview || '/Car-Preview.png'}
                alt="Vehicle"
              />

              <PText size="x-small" weight="bold" align="center">
                {vehicle.derivative}
              </PText>
              <PText size="x-small" align="center">{vehicle.vin}</PText>
              <PText size="x-small" align="center">
                {t('sharedView.vehicleNettoPrice')}:{' '}
                {priceFormatting(Number(vehicle.initial_price))}
              </PText>
            </div>
          ))}
        </PScroller>
      </div>

      <div className={styles.packageModalContainer}>
        <div className={styles.left}>
          <PTextFieldWrapper
            label={t('sellerView.packageInitialPrice') + ` (${t('sharedView.net')})`}
            unit="€"
            unitPosition="suffix"
            state={
              (packageInitialPrice > 0)
                ? 'none'
                : 'error'
            }
          >
            <NumericFormat
              name="initial-price"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
              value={packageInitialPrice}
              onValueChange={(e) => setPackageInitialPrice(e.floatValue ?? 0)}
            />
          </PTextFieldWrapper>

          <PTextFieldWrapper
            label={t('sharedView.auctionStartDate')}
            state={
              (startDate !== '' && !validStartDate(startDate)) ||
              !startDateIsNotOnWeekend(startDate)
                ? 'error'
                : 'none'
            }
            message={t(
              'sellerView.dateMustBeInTheFutureAndNotOnAWeekend'
            ).toString()}
          >
            <input
              type="date"
              name="start-date"
              value={startDate}
              required
              onChange={(e) => setStartDate(e.target.value)}
            />
          </PTextFieldWrapper>
        </div>
        <div className={styles.right}>
          <PTextareaWrapper
            label={t('sharedView.vehicleNotes')}
            hideLabel={false}
            showCounter={false}
          >
            <textarea
              className={styles.packageModalTextarea}
              name="notes"
              maxLength={250}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </PTextareaWrapper>
        </div>
      </div>

      <PButtonGroup className={styles.packageModalButtons}>
        <PButton
          type="button"
          aria-label="save-button"
          disabled={
            !(packageInitialPrice > 0) ||
            startDate === '' ||
            !validStartDate(startDate) ||
            !startDateIsNotOnWeekend(startDate)
          }
          onClick={createPackage}
        >
          {t('sellerView.saveButton')}
        </PButton>
        <PButton
          type="button"
          variant="tertiary"
          icon="close"
          onClick={() => props.onDismiss()}
        >
          {t('sellerView.cancelButton')}
        </PButton>
      </PButtonGroup>
    </PModal>
  )
}

export default PackageModal
