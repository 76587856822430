import { Auction } from '../Models/Auction'
import { convertUtcTimeToGermanTime, isTodaysAuction } from './helperFunctions'

export const VEHICLE_STATUS = {
  blocked: 'sellerView.blockedCarsTabTitle',
  returned: 'sellerView.returnedCarsTabTitle',
  sale: 'sharedView.liveSaleVehicleStatus',
  zulauf: 'sellerView.incomingCarsTabTitle',
  preview: 'dealerView.previewTabTitle',
  ready: 'sellerView.readyForSaleTabTitle',
  sold: 'sellerView.soldCarsTabTitle',
  unknown: '—',
  previously_auctioned: 'sellerView.failedAuctionStatus'
}

export const determineVehicleStatus = (
  primaryStatus?: string,
  secondaryStatus?: string,
  blocked?: boolean,
  auction?: Auction,
  isSeller?: boolean,
  isDealer?: boolean,
  hasACompleteAuction?: boolean
) => {
  if (blocked || primaryStatus === 'YF70') return VEHICLE_STATUS.blocked
  if (secondaryStatus !== 'QS20') return VEHICLE_STATUS.unknown
  if (auction?.winner_notified) return VEHICLE_STATUS.sold
  if (primaryStatus === 'YF40') {
    //TODO vehicle status should not depend on dealer or seller. Investigate and move out this logic
    if (isSeller && isActive(auction)) {
      return VEHICLE_STATUS.sale
    } else if (isFutureAuction(auction)) {
      return isDealer ? VEHICLE_STATUS.preview : VEHICLE_STATUS.ready
    } else if (isSeller) {
      return hasACompleteAuction
        ? VEHICLE_STATUS.previously_auctioned
        : VEHICLE_STATUS.returned
    } else {
      return 'dealerSale'
    }
  }
  if (primaryStatus === 'YF40') return VEHICLE_STATUS.returned
  if (['YF20', 'YF30', 'YF35'].includes(primaryStatus as string))
    return VEHICLE_STATUS.zulauf

  return VEHICLE_STATUS.unknown
}

export const determineRoute = (
  primaryStatus?: string,
  secondaryStatus?: string,
  blocked?: boolean,
  auction?: Auction,
  isSeller?: boolean,
  isDealer?: boolean
) => {
  const vehicleStatus = determineVehicleStatus(
    primaryStatus,
    secondaryStatus,
    blocked,
    auction,
    isSeller,
    isDealer
  )
  switch (vehicleStatus) {
    case VEHICLE_STATUS.zulauf:
      return '/'
    case VEHICLE_STATUS.blocked:
      return '/blocked'
    case VEHICLE_STATUS.returned:
      return '/returned'
    case VEHICLE_STATUS.sale:
      return '/sale'
    case VEHICLE_STATUS.sold:
      return '/sold'
    case VEHICLE_STATUS.preview:
      return routeForFutureAuction(auction, isSeller, isDealer)
    case VEHICLE_STATUS.ready:
      return routeForFutureAuction(auction, isSeller, isDealer)
    default:
      return '/'
  }
}

const routeForFutureAuction = (auction?: Auction, isSeller?: boolean, isDealer?: boolean) => {
  if (!isTodaysAuction(auction)) return isDealer ? '/preview' : '/ready'
  return isSeller ? '/sale' : '/'
}

export const isActive = (auction?: Auction) => {
  if (auction == null) return false

  const timeNow = convertUtcTimeToGermanTime(new Date())

  return (
    convertUtcTimeToGermanTime(new Date(auction.start_date)) <= timeNow &&
    timeNow <= convertUtcTimeToGermanTime(new Date(auction.end_date))
  )
}

export const isFutureAuction = (auction?: Auction) => {
  if (auction == null) return false

  return (
    convertUtcTimeToGermanTime(new Date(auction.start_date)) >
    convertUtcTimeToGermanTime(new Date())
  )
}
