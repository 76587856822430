import type {TabsUpdateEvent} from '@porsche-design-system/components-react'
import {PTabsBar} from '@porsche-design-system/components-react'
import {useCallback, useContext, useEffect, useState} from 'react'
import styles from './TabsBar.module.css'
import {Link, Outlet, useLocation} from 'react-router-dom'
import {UserInfo} from '../../App'
import {useTranslation} from 'react-i18next'
import loadEnvironment from "../../loadEnvironment";

export const TabsBar = (): JSX.Element => {
  const {t} = useTranslation()
  const [tabIndex, setTabIndex] = useState<number>(0)
  const {pathname} = useLocation()
  const userInfo = useContext(UserInfo)
  const environment = loadEnvironment()

  useEffect(() => {
    let sellerTabList = ['/returned', '/ready', '/sale', '/sold', '/blocked']
    let dealerTabList = ['/preview', '/', '/sold']
    let adminTabList = sellerTabList.concat('/analytics')

    let tabList = userInfo.isSeller
      ? ( userInfo.isAdmin ? adminTabList : sellerTabList)
      : dealerTabList
    if (environment.FEATURE_TOGGLES.SHOW_INCOMING_VEHICLES_TAB && userInfo.isSeller) {
      tabList.unshift('/')
    }
    const activePathname = (userInfo.isSeller && pathname === '/') ? '/returned' : pathname;
    const index = tabList.findIndex((e) => e === activePathname)
    setTabIndex(index)
  }, [userInfo, pathname, environment])

  const onUpdate = useCallback((e: CustomEvent<TabsUpdateEvent>) => {
    setTabIndex(e.detail.activeTabIndex)
  }, [])

  return (
    <>
      <div className={styles.whiteBackground}>
        <PTabsBar
          size="medium"
          activeTabIndex={tabIndex}
          className={styles.TabsBar}
          onUpdate={onUpdate}
        >
          {userInfo.isSeller ? (
            <>
              {environment.FEATURE_TOGGLES.SHOW_INCOMING_VEHICLES_TAB && (
                <Link to={'/'}>{t('sellerView.incomingCarsTabTitle')}</Link>
              )}
              <Link aria-label="returned-tab" to={'/returned'}>{t('sellerView.returnedCarsTabTitle')}</Link>
              <Link aria-label="ready-tab" to={'/ready'}>{t('sellerView.readyForSaleTabTitle')}</Link>
              <Link to={'/sale'}>{t('sharedView.liveSaleTabTitle')}</Link>
              <Link to={'/sold'}>{t('sellerView.soldCarsTabTitle')}</Link>
              <Link to={'/blocked'}>{t('sellerView.blockedCarsTabTitle')}</Link>
              {userInfo.isAdmin && (
                <Link to={'/analytics'}>{t('adminView.analyticsTabTitle')}</Link>
              )}
            </>
          ) : (
            userInfo.isDealer && (
              <>
                <Link to={'/preview'}>{t('dealerView.previewTabTitle')}</Link>
                <Link to={'/'}>{t('sharedView.liveSaleTabTitle')}</Link>
                <Link to={'/sold'}>
                  {t('dealerView.purchasedCarsTabTitle')}
                </Link>
              </>
            )
          )}
        </PTabsBar>
      </div>

      <main>
        <Outlet/>
      </main>
    </>
  )
}
