import React, { useCallback, useEffect, useState } from 'react'
import styles from '../../IncomingTab/IncomingVehiclesContent.module.css'
import {
  PPopover,
  PSpinner,
  PTable,
  PTableBody,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PText,
} from '@porsche-design-system/components-react'
import { STATUS_FILTER } from '../../../../Hooks/statusFilterConstants'
import {
  AVAILABLE_DERIVATIVE_FILTERS,
  AVAILABLE_MODEL_FILTERS,
  NO_DERIVATIVE_SELECTED,
} from '../../../Filters/FilterConstants'
import useInfiniteVehicles from '../../../../Hooks/useInfiniteVehicles'
import ReadyForSaleVehicleRow from './ReadyForSaleVehicleRow'
import FilterRow from '../../../Filters/FilterRow'
import { useTranslation } from 'react-i18next'
import {
  readFromSessionStorage,
  useSessionStorage,
} from '../../../../Hooks/filterHooks'

const primaryStatusFilter = [STATUS_FILTER[3]]

export default function ReadyForSaleVehiclesContent(props: {
  pageSize: number
}) {
  const { t } = useTranslation()
  const [modelFilter, setModelFilter] = useState(
    readFromSessionStorage(
      'modelFilter',
      AVAILABLE_MODEL_FILTERS[0],
      (x: string) => AVAILABLE_MODEL_FILTERS.includes(x),
    ),
  )
  useSessionStorage(modelFilter, 'modelFilter')
  const [derivativeFilter, setDerivativeFilter] = useState(
    readFromSessionStorage(
      'derivativeFilter',
      NO_DERIVATIVE_SELECTED,
      (x: string) => x.split(',').find(z => AVAILABLE_DERIVATIVE_FILTERS.includes(z)) !== undefined
    ).split(','),
  )
  useSessionStorage(derivativeFilter.toString(), 'derivativeFilter')
  const [arbitraryDateFilter, setArbitraryDateFilter] = useState(
    readFromSessionStorage('arbitraryDateFilter', '', (_: string) => true),
  )
  useSessionStorage(arbitraryDateFilter, 'arbitraryDateFilter')
  const [pageIndex, setPageIndex] = useState(1)
  const { vehicles, totalVehicles, nextPage } = useInfiniteVehicles(modelFilter, derivativeFilter, primaryStatusFilter, '', false, true, true, pageIndex, props.pageSize, undefined, undefined, arbitraryDateFilter)

  const handleScroll = useCallback(() => {
    const e = document.documentElement
    if (e.scrollTop + e.clientHeight >= e.scrollHeight - 300) {
      pageIndex !== nextPage &&
        totalVehicles - props.pageSize * pageIndex > 0 &&
        setPageIndex(nextPage)
    }
  }, [props.pageSize, pageIndex, nextPage, totalVehicles, setPageIndex])

  useEffect(() => {
    const delay = window.setTimeout(handleScroll, 100)
    return () => window.clearTimeout(delay)
  }, [vehicles, handleScroll])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(
    () => setPageIndex(1),
    [modelFilter, derivativeFilter, arbitraryDateFilter],
  )

  return (
    <>
      <PText className={styles.title} size="medium" weight="semi-bold">
        {t('sharedView.singleAuction')}
      </PText>
      <PText className={styles.listSize} size="small" weight="semi-bold">
        {t('sharedView.vehicleCount', { count: totalVehicles })}
      </PText>
      <FilterRow
        onChangeModel={setModelFilter}
        modelFilter={modelFilter}
        onChangeDerivative={setDerivativeFilter}
        derivativeFilter={derivativeFilter}
        onChangeArbitraryDate={setArbitraryDateFilter}
        arbitraryDateFilter={arbitraryDateFilter}
      />
      {totalVehicles !== 0 && (
        <>
          <PTable caption="Some caption" className={styles.contentTable}>
            <PTableHead>
              <PTableHeadRow>
                <PTableHeadCell role="columnheader">
                  <PText weight="semi-bold">
                    {t('sharedView.vehiclePictures')}
                  </PText>
                </PTableHeadCell>
                <PTableHeadCell role="columnheader">
                  <PText weight="semi-bold">
                    {t('sharedView.vehicleModel')}
                  </PText>
                  <PText weight="semi-bold">
                    {t('sharedView.vehicleIdentificator')}
                  </PText>
                </PTableHeadCell>
                <PTableHeadCell role="columnheader">
                  <PText weight="semi-bold">
                    {t('sharedView.vehicleExteriorColor')}
                  </PText>
                  <PText weight="semi-bold">
                    {t('sharedView.vehicleInteriorColor')}
                  </PText>
                </PTableHeadCell>
                <PTableHeadCell role="columnheader">
                  <PText weight="semi-bold">
                    {t('sharedView.firstRegistration')}
                  </PText>
                  <div className={styles.inlinePopoverContainer}>
                    <PText weight="semi-bold">{t('sharedView.mileage')}</PText>
                    <PPopover
                      className={styles.popover}
                      aria={{ 'aria-label': 'Additional information' }}
                    >
                      {t('sharedView.vehicleMileageInfo')}
                    </PPopover>
                  </div>
                </PTableHeadCell>
                <PTableHeadCell role="columnheader">
                  <div className={styles.inlinePopoverContainer}>
                    <PText weight="semi-bold">
                      {t('sharedView.vehicleInitialPrice')}
                    </PText>
                    <PPopover
                      className={styles.popover}
                      aria={{
                        'aria-label': 'Initial price additional information',
                      }}
                    >
                      {t('sharedView.vehicleNettoPrice')}
                    </PPopover>
                  </div>
                  <div className={styles.inlinePopoverContainer}>
                    <PText weight="semi-bold">UPE</PText>
                    <PPopover
                      className={styles.popover}
                      aria={{
                        'aria-label':
                          'Vehicle listing price additional information',
                      }}
                    >
                      {t('sharedView.vehicleGrossPrice')}
                    </PPopover>
                  </div>
                </PTableHeadCell>
                <PTableHeadCell role="columnheader">
                  <PText weight="semi-bold">
                    {t('sharedView.auctionStartDate')}
                  </PText>
                </PTableHeadCell>
                <PTableHeadCell role="columnheader"></PTableHeadCell>
              </PTableHeadRow>
            </PTableHead>
            <PTableBody>
              {vehicles?.map((item, i) => (
                <ReadyForSaleVehicleRow key={i} vehicle={item} />
              ))}
            </PTableBody>
          </PTable>
          {pageIndex !== nextPage &&
            totalVehicles - props.pageSize * pageIndex <= 0 && (
              <PText>
                {t('exceptions.reachedEndOfList')}
                <br />
                <br />
              </PText>
            )}
        </>
      )}
      {(!nextPage || pageIndex === nextPage) && (
        <PSpinner
          size="medium"
          aria={{ 'aria-label': 'Loading page content' }}
        />
      )}
      <div className={styles.pagination} />
    </>
  )
}
