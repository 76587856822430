import React from 'react'
import PreviewVehiclesContent from './PreviewSingleAuctions/PreviewVehiclesContent'
import PreviewPackagesContent from './PreviewPackageAuctions/PreviewPackagesContent'

export default function PreviewTab() {
  return (
    <>
      <PreviewPackagesContent pageSize={10} />
      <PreviewVehiclesContent pageSize={20} />
    </>
  )
}
