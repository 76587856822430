import { useState, useEffect, useCallback } from "react";
import {
  PIcon,
  PTableHeadCell,
  PTableCell,
  PText,
  PButton,
} from '@porsche-design-system/components-react'
import { useTranslation } from 'react-i18next'
import loadEnvironment from '../loadEnvironment'
import { callBackendAPI } from "../api";
import { Auction } from "../Models/Auction";

const environment = loadEnvironment()

export const FavouriteTableHeadCell = () => {
  const { t } = useTranslation()

  return (
    <PTableHeadCell role="columnheader">
      <PText weight="semi-bold">
        {t('dealerView.favorites')}
      </PText>
    </PTableHeadCell>
  );
}

export const FavouriteTableCell = (props: { auction: Auction }) => {

  return (
    <PTableCell role="cell" aria-label="favourite table cell">
      <FavouriteButton auction={props.auction} variant="icon" />
    </PTableCell>
  );
}

export const FavouriteButton = (props: { auction: Auction, variant: 'button' | 'icon' }) => {
  const { t } = useTranslation()
  const [isFavourite, setIsFavourite] = useState(false)
  const [favouriteIcon, setFavouriteIcon] = useState<'star' | 'star-filled'>('star')

  useEffect(() => {
    if(props.auction.is_favourite){
      setIsFavourite(true)
      setFavouriteIcon('star-filled')
    } else {
      setIsFavourite(false)
      setFavouriteIcon('star')
    }
  }, [props.auction.is_favourite]);

  const onToggleHandler = useCallback(() => {
    const markFavourite = () => callBackendAPI(
      `${environment.B2B_BACKEND_BASE_URL}/api/favourite/auction`,
      'POST',
      {
        auctionId: props.auction._id,
      }
    ).then((response) => {
      if (response.status === 200) {
        switch (response.data.success) {
          case 0:
          case 1:
            setIsFavourite(true)
            setFavouriteIcon('star-filled')
        }
      }
    }).catch((error) => {
      // todo: handle error
    })

    const unmarkFavourite = () => callBackendAPI(
      `${environment.B2B_BACKEND_BASE_URL}/api/favourite/auction`,
      'DELETE',
      {
        auctionId: props.auction._id,
      }
    ).then((response) => {
      if (response.status === 200) {
        switch (response.data.success) {
          case 0:
          case 1:
            setIsFavourite(false)
            setFavouriteIcon('star')
        }
      }
    }).catch((error) => {
      // todo: handle error
    })

    isFavourite ? unmarkFavourite() : markFavourite()
  }, [props.auction._id, isFavourite])

  const onMouseOverHandler = useCallback(() => {
    if (!isFavourite) {
      setFavouriteIcon('star-filled')
    }
  }, [isFavourite])

  const onMouseOutHandler = useCallback(() => {
    if (!isFavourite) {
      setFavouriteIcon('star')
    }
  }, [isFavourite])

  return props.variant === 'icon' ? (
      <PIcon
        name={favouriteIcon}
        size="large"
        aria={{ 'aria-label': 'Favourite Icon' }}
        onClick={onToggleHandler}
        onMouseOver={onMouseOverHandler}
        onMouseOut={onMouseOutHandler}
        style={{ cursor: 'pointer' }}
        data-testid={favouriteIcon}
      />
  ) : (
      <PButton
        variant="secondary"
        icon={favouriteIcon}
        onClick={onToggleHandler}
        aria={{ 'aria-label': 'Favourite button' }}
        onMouseOver={onMouseOverHandler}
        onMouseOut={onMouseOutHandler}
        data-testid={favouriteIcon}
      >
        {t('dealerView.favorite')}
      </PButton>
  )
}
