import { PText } from '@porsche-design-system/components-react'
import React from 'react'
import styles from './Custom404Page.module.css'

const Custom404Page = () => {
  return (
    <div className={styles.container}>
      <div className={styles.emojiContainer}>
        <span className={styles.car}>&#128663;</span>
        <span className={styles.magnifyingGlass}>&#128269;</span>
      </div>
      <div className={styles.message}>
        <PText size="large" color="notification-error">
          <span>404 - Vehicle Not Found</span>
        </PText>
        <PText>
          <br />
          Sorry, the car you are looking for doesn't exist in our system.
          <br />
          Maybe it's an invisible car!
        </PText>
      </div>
    </div>
  )
}

export default Custom404Page
