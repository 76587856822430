import { Vehicle } from '../../../Models/Vehicle'
import styles from './DetailsPage.module.css'
import {
  PDivider,
  PHeading,
  PText,
} from '@porsche-design-system/components-react'
import { useTranslation } from 'react-i18next'

export const createDetailRow = (key: string, value?: string) => {
  return (
    <>
      <div className={styles.detailRow}>
        <PText color="contrast-medium">{key}</PText>
        <PText className={styles.equipmentCode}>{value || '—'}</PText>
      </div>
      <PDivider />
    </>
  )
}

export const ModelInformationBlock = (props: {
  vehicleWithDetails?: Vehicle | null
}) => {
  const { t } = useTranslation()
  const details = props?.vehicleWithDetails?.details
  return (
    <div className={styles.detailBlock}>
      <PHeading className={styles.sectionHeadlines} tag="h3" size="large">
        {t('sharedView.modelInformationBlockTitle')}
      </PHeading>
      <div className={styles.modelInformationDetails}>
        {createDetailRow(
          t('sharedView.vehicleModelYear'),
          details?.model_year?.toString()
        )}
        {createDetailRow(
          t('sharedView.vehicleOrderType'),
          details?.order_type_code
        )}
        {createDetailRow(
          t('sharedView.vehicleCountrySpecification'),
          details?.country_specification
        )}
        {createDetailRow(
          t('sharedView.vehicleModelGeneration'),
          details?.model_generation
        )}
        {createDetailRow(t('sharedView.vehicleModel'), details?.model_series)}
        {createDetailRow(
          t('sharedView.vehicleDerivative'),
          props?.vehicleWithDetails?.derivative
        )}
        {createDetailRow(
          t('sharedView.vehicleDrivetrain'),
          details?.drive_train
        )}
        {createDetailRow(
          t('sharedView.vehicleEngineType'),
          details?.engine_type
        )}
        {createDetailRow(
          t('sharedView.vehicleTransmission'),
          details?.transmission
        )}
      </div>
    </div>
  )
}
