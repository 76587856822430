import { VehicleEquipment } from '../../../Models/VehicleDetails'
import styles from './DetailsPage.module.css'
import { PHeading, PText } from '@porsche-design-system/components-react'

export const EquipmentBlock = (props: { equipment?: VehicleEquipment[][] }) => {
  const equipment = props.equipment

  const populateCategorySection = (
    equipmentsInCategory: VehicleEquipment[]
  ) => {
    return equipmentsInCategory.map((eq) => (
      <div key={`${eq.option_code}-${eq.name}`} className={styles.equipment}>
        <PText
          color="contrast-medium"
          className={styles.equipmentCode}
        >{`${eq.option_code} | `}</PText>
        <PText>{eq.name || '–'}</PText>
      </div>
    ))
  }

  const generateEquipmentContent = () => {
    return equipment?.map((equipmentsInCategory) => {
      if (
        ['OTHERS', 'PCCD_OTHERS'].includes(
          equipmentsInCategory[0].category?.key as string
        )
      )
        return null

      return (
        <div
          key={equipmentsInCategory[0].category?.name}
          className={styles.equipmentCategory}
        >
          <PHeading
            className={styles.equipmentSubHeader}
            tag="h5"
            size="medium"
          >
            {equipmentsInCategory[0].category?.name}
          </PHeading>
          {populateCategorySection(equipmentsInCategory)}
        </div>
      )
    })
  }

  return (
    <div className={styles.detailBlock}>
      <PHeading tag="h3" size="large" className={styles.sectionHeadlines}>
        Equipment
      </PHeading>
      <div className={styles.equipmentCategoryWrapper}>
        {generateEquipmentContent()}
      </div>
    </div>
  )
}
