import React, { useCallback, useEffect, useState } from 'react'
import styles from './IncomingVehiclesContent.module.css'
import {
  PaginationUpdateEvent,
  PPagination,
  PPopover,
  PTable,
  PTableBody,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PText,
} from '@porsche-design-system/components-react'
import {
  SECONDARY_STATUSES,
  STATUS_FILTER,
} from '../../../Hooks/statusFilterConstants'
import {
  AVAILABLE_MODEL_FILTERS,
  NO_DERIVATIVE_SELECTED,
} from '../../Filters/FilterConstants'
import useVehicles from '../../../Hooks/useVehicles'
import IncomingVehicleRow from './IncomingVehicleRow'
import FilterRow from '../../Filters/FilterRow'
import { useTranslation } from 'react-i18next'

const primaryStatusFilter = [
  STATUS_FILTER[0],
  STATUS_FILTER[1],
  STATUS_FILTER[2],
]
const secondaryStatusFilter = SECONDARY_STATUSES.NO_PRE_CONTRACTS
const blockedForSalesFilter = false
const futureAuctionsFilter = false
const ignoreSoldCars = false

export default function IncomingVehiclesContent(props: { pageSize: number }) {
  const { t } = useTranslation()
  const [modelFilter, setModelFilter] = useState(AVAILABLE_MODEL_FILTERS[0])
  const [derivativeFilter, setDerivativeFilter] = useState(
    [NO_DERIVATIVE_SELECTED]
  )
  const [pageIndex, setPageIndex] = useState(1)

  const { vehicles, totalVehicles } = useVehicles(modelFilter, derivativeFilter, primaryStatusFilter, secondaryStatusFilter, blockedForSalesFilter, futureAuctionsFilter, ignoreSoldCars, pageIndex, props.pageSize)

  const onUpdate = useCallback((e: CustomEvent<PaginationUpdateEvent>) => {
    setPageIndex(e.detail.page)
  }, [])

  useEffect(() => setPageIndex(1), [modelFilter, derivativeFilter])

  return (
    <>
      <FilterRow
        onChangeModel={setModelFilter}
        modelFilter={modelFilter}
        onChangeDerivative={setDerivativeFilter}
        derivativeFilter={derivativeFilter}
        onChangeArbitraryDate={null}
        arbitraryDateFilter={null}
      />
      <PText className={styles.listSize} size="small" weight="semi-bold">
        {t('sharedView.vehicleCount', { count: totalVehicles })}
      </PText>
      <PTable caption="Some caption">
        <PTableHead>
          <PTableHeadRow>
            <PTableHeadCell role="columnheader">
              <PText weight="semi-bold">
                {t('sharedView.vehiclePictures')}
              </PText>
            </PTableHeadCell>
            <PTableHeadCell role="columnheader">
              <PText weight="semi-bold">{t('sharedView.vehicleModel')}</PText>
              <PText weight="semi-bold">
                {t('sharedView.vehicleIdentificator')}
              </PText>
            </PTableHeadCell>
            <PTableHeadCell role="columnheader">
              <PText weight="semi-bold">
                {t('sharedView.vehicleExteriorColor')}
              </PText>
              <PText weight="semi-bold">
                {t('sharedView.vehicleInteriorColor')}
              </PText>
            </PTableHeadCell>
            <PTableHeadCell role="columnheader">
              <div className={styles.inlinePopoverContainer}>
                <PText weight="semi-bold">{t('sharedView.mileage')}</PText>
                <PPopover
                  className={styles.popover}
                  aria={{ 'aria-label': 'Additional information' }}
                >
                  {t('sharedView.vehicleMileageInfo')}
                </PPopover>
              </div>
            </PTableHeadCell>
            <PTableHeadCell role="columnheader">
              <PText weight="semi-bold">
                {t('sharedView.vehicleReturnDate')}
              </PText>
            </PTableHeadCell>
          </PTableHeadRow>
        </PTableHead>
        <PTableBody>
          {vehicles?.map((item, i) => (
            <IncomingVehicleRow key={i} vehicle={item} />
          ))}
        </PTableBody>
      </PTable>
      {totalVehicles > props.pageSize && (
        <PPagination
          className={styles.pagination}
          totalItemsCount={totalVehicles}
          itemsPerPage={props.pageSize}
          activePage={pageIndex}
          onUpdate={onUpdate}
        />
      )}
    </>
  )
}
