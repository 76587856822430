import React, { useCallback, useContext, useEffect, useState } from 'react'
import styles from '../../IncomingTab/IncomingVehiclesContent.module.css'
import {
  PaginationUpdateEvent,
  PPagination, PPopover,
  PTable,
  PTableBody,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PText,
} from '@porsche-design-system/components-react'

import LiveSalePackageRow from './LiveSalePackageRow'
import { UserInfo } from '../../../../App'
import { useBids } from '../../../../Hooks/useBids'
import { useTranslation } from 'react-i18next'
import usePackageAuctions, {
  TAB_LIVE_SALE,
} from '../../../../Hooks/usePackageAuctions'
import { AuctionCountdown } from '../../../AuctionCountdown'
import { FavouriteTableHeadCell } from '../../../FavouriteAuction'
import loadEnvironment from '../../../../loadEnvironment'
import {HotBiddingHead} from "../HotBiddingCell";


export type AuctionStatus = {
  currentHighestBid: number
  maxBidId: string
  totalBids: number
}

export type WebSocketNotification = {
  auctionId: string
  currentHighestBid: number
  maxBidId: string
  totalBids: number
}

export type AuctionStatusMapping = {
  [auctionId: string]: AuctionStatus
}

export default function LiveSalePackagesContent(props: {
  pageSize: number
  auctionStatusMapping: AuctionStatusMapping
}) {
  const { t } = useTranslation()
  const [pageIndex, setPageIndex] = useState(1)
  const auctionStatusMapping = props.auctionStatusMapping
  const { isSeller, isDealer } = useContext(UserInfo)
  const environment = loadEnvironment()

  const { auctions, totalAuctions } = usePackageAuctions(
    TAB_LIVE_SALE,
    pageIndex
  )

  const [refreshKey, setRefreshKey] = useState(0)
  const [bids, lastRefresh] = useBids(refreshKey)

  const onUpdate = useCallback((e: CustomEvent<PaginationUpdateEvent>) => {
    setPageIndex(e.detail.page)
  }, [])

  useEffect(() => setPageIndex(1), [])

  return (
    <div className={styles.packagesContent}>
      <PText className={styles.title} size="medium" weight="semi-bold">
        {t('sharedView.packageAuction')}
      </PText>
      <div className={styles.countDownRow}>
        <PText className={styles.listSize} size="small" weight="semi-bold">
          {t('sharedView.packageCount', { count: totalAuctions })}
        </PText>
        <AuctionCountdown className={styles.countDown} />
      </div>
      {totalAuctions !== 0 && (
        <PTable caption="Some caption">
          <PTableHead>
            <PTableHeadRow>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">
                  {t('sharedView.vehiclePictures')}
                </PText>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">{t('sharedView.packageNumber')}</PText>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">
                  {t('sharedView.numberOfVehicles')}
                </PText>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                {isSeller && (
                    <div className={styles.inlinePopoverContainer}>
                    <PText weight="semi-bold">
                      {t('sharedView.vehicleInitialPrice')}
                    </PText>
                        <PPopover
                            className={styles.popover}
                            aria={{ 'aria-label': 'Initial price additional information' }}
                        >
                          {t('sharedView.vehicleNettoPrice')}
                        </PPopover>
                    </div>
                )}
                {isDealer && (
                  <>
                    <div className={styles.inlinePopoverContainer}>
                      <PText weight="semi-bold">
                        {t('dealerView.currentHighestBid')}
                      </PText>
                        <PPopover
                            className={styles.popover}
                            aria={{ 'aria-label': 'Package current highest bid additional information' }}
                        >
                          {t('sharedView.vehicleNettoPrice')}
                        </PPopover>
                    </div>
                    <div className={styles.inlinePopoverContainer}>
                      <PText weight="semi-bold">
                        {t('dealerView.myMaximumBid')}
                      </PText>
                        <PPopover
                            className={styles.popover}
                            aria={{ 'aria-label': 'My maximum package bid additional information' }}
                        >
                          {t('sharedView.vehicleNettoPrice')}
                        </PPopover>
                  </div>
                  </>
                )}
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">{t('sharedView.bidsAmount')}</PText>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                {isDealer && (
                  <PText weight="semi-bold">{t('dealerView.myStatus')}</PText>
                )}
                {isSeller && (
                    <div className={styles.inlinePopoverContainer}>
                      <PText weight="semi-bold">
                        Live {t('sellerView.vehiclePriceColumn')}
                      </PText>
                          <PPopover
                              className={styles.popover}
                              aria={{ 'aria-label': 'Package live price additional information' }}
                          >
                            {t('sharedView.vehicleNettoPrice')}
                          </PPopover>
                    </div>
                )}
              </PTableHeadCell>
              {isDealer && (
                  <FavouriteTableHeadCell/>
              )}
              {environment.FEATURE_TOGGLES.ENABLE_HOT_BIDDING && isDealer && <HotBiddingHead />}
            </PTableHeadRow>
          </PTableHead>
          <PTableBody>
            {auctions?.map((item, i) => (
              <LiveSalePackageRow
                key={i}
                auction={item}
                userMaxBid={bids
                  .filter((bid) => bid.auction_id === item._id)
                  .sort(
                    (bid_a, bid_b) =>
                      Number(bid_a.bid_amount) - Number(bid_b.bid_amount)
                  )
                  .reverse()[0]}
                initialPrice={
                  Number(item.initial_price)
                }
                currentHighestBid={
                  auctionStatusMapping[item._id]
                    ? auctionStatusMapping[item._id].currentHighestBid
                    : item.current_bid
                    ? Number(item.current_bid)
                    : Number(0)
                }
                maxBidId={
                  auctionStatusMapping[item._id]
                    ? auctionStatusMapping[item._id].maxBidId
                    : item.max_bid_id
                }
                totalBids={
                  auctionStatusMapping[item._id]
                    ? auctionStatusMapping[item._id].totalBids
                    : Number(item.total_bids) || 0
                }
                bidListUpdaterSerial={lastRefresh}
                resetFn={() => {
                  setRefreshKey(prevKey => prevKey + 1)
                  return refreshKey + 1
                }}
              />
            ))}
          </PTableBody>
        </PTable>
      )}
      {totalAuctions > props.pageSize && (
        <PPagination
          className={styles.pagination}
          totalItemsCount={totalAuctions}
          itemsPerPage={props.pageSize}
          activePage={pageIndex}
          onUpdate={onUpdate}
        />
      )}
    </div>
  )
}
