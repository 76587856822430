const AVAILABLE_MODEL_FILTERS = [
  'sharedView.allModels',
  '911',
  'Taycan',
  'Panamera',
  'Macan',
  'Cayenne',
  '718',
]

const AVAILABLE_DERIVATIVE_FILTERS = `718 Boxster
718 Boxster GTS 4.0
718 Boxster S
718 Boxster Style Edition
718 Boxster T
718 Cayman
718 Cayman GT4
718 Cayman GT4 RS
718 Cayman GTS 4.0
718 Cayman S
718 Cayman Style Edition
718 Cayman T
718 Spyder
718 Spyder RS
911 Carrera
911 Carrera 4
911 Carrera 4 Cabriolet
911 Carrera 4 GTS
911 Carrera 4 GTS Cabriolet
911 Carrera 4S
911 Carrera 4S Cabriolet
911 Carrera Cabriolet
911 Carrera GTS
911 Carrera GTS Cabriolet
911 Carrera S
911 Carrera S Cabriolet
911 Carrera T
911 GT3
911 GT3 mit Touring-Paket
911 GT3 RS
911 Targa 4
911 Targa 4 GTS
911 Targa 4S
911 Turbo
911 Turbo Cabriolet
911 Turbo S
911 Turbo S Cabriolet
Cayenne
Cayenne Coupé
Cayenne Coupé Platinum Edition
Cayenne E-Hybrid
Cayenne E-Hybrid Coupé
Cayenne E-Hybrid Coupé Platinum Edition
Cayenne E-Hybrid Platinum Edition
Cayenne GTS
Cayenne GTS Coupé
Cayenne Platinum Edition
Cayenne S
Cayenne S Coupé
Cayenne S Coupé Platinum Edition
Cayenne S E-Hybrid
Cayenne S E-Hybrid Coupé
Cayenne S Platinum Edition
Cayenne Turbo
Cayenne Turbo Coupé
Cayenne Turbo E-Hybrid
Cayenne Turbo E-Hybrid Coupé
Cayenne Turbo E-Hybrid Coupé mit GT-Paket
Cayenne Turbo GT
Cayenne Turbo S E-Hybrid
Cayenne Turbo S E-Hybrid Coupé
Macan
Macan 4
Macan GTS
Macan S
Macan T
Macan Turbo
Panamera
Panamera 4
Panamera 4 E-Hybrid
Panamera 4 E-Hybrid Executive
Panamera 4 E-Hybrid Platinum Edition
Panamera 4 E-Hybrid Sport Turismo
Panamera 4 E-Hybrid Sport Turismo Platinum Edition
Panamera 4 Executive
Panamera 4 Platinum Edition
Panamera 4 Sport Turismo
Panamera 4 Sport Turismo Platinum Edition
Panamera 4S
Panamera 4S E-Hybrid
Panamera 4S E-Hybrid Executive
Panamera 4S E-Hybrid Sport Turismo
Panamera 4S Executive
Panamera 4S Sport Turismo
Panamera GTS
Panamera GTS Sport Turismo
Panamera Turbo E-Hybrid
Panamera Turbo S
Panamera Turbo S E-Hybrid
Panamera Turbo S E-Hybrid Executive
Panamera Turbo S E-Hybrid Sport Turismo
Panamera Turbo S Executive
Panamera Turbo S Sport Turismo
Taycan
Taycan 4
Taycan 4 Cross Turismo
Taycan 4S
Taycan 4S Cross Turismo
Taycan 4S Sport Turismo
Taycan GTS
Taycan GTS Sport Turismo
Taycan Sport Turismo
Taycan Turbo
Taycan Turbo Cross Turismo
Taycan Turbo GT
Taycan Turbo GT mit Weissach-Paket
Taycan Turbo S
Taycan Turbo S Cross Turismo
Taycan Turbo S Sport Turismo
Taycan Turbo Sport Turismo`.split('\n')

const NO_DERIVATIVE_SELECTED = 'sharedView.allDerivatives'

function derivativesByModel(model: string): string[] {
  return [
    NO_DERIVATIVE_SELECTED,
    ...AVAILABLE_DERIVATIVE_FILTERS.filter((derivative) =>
      derivative.toLowerCase().startsWith(model.toLowerCase())
    ),
  ]
}

export {
  AVAILABLE_MODEL_FILTERS,
  AVAILABLE_DERIVATIVE_FILTERS,
  NO_DERIVATIVE_SELECTED,
  derivativesByModel,
}
