import React, { useContext, useState } from 'react'
import LiveSaleVehiclesContent, {
  AuctionStatus,
} from './LiveSaleSingleAuctions/LiveSaleVehiclesContent'
import LiveSalePackagesContent from './LiveSalePackageAuctions/LiveSalePackagesContent'
import Websocket from '../../Websocket'
import { UserInfo } from '../../../App'

export type WebSocketNotification = {
  auctionId: string
  currentHighestBid: number
  maxBidId: string
  totalBids: number
}

export default function LiveSaleTab() {
  const { isSeller, isDealer } = useContext(UserInfo)

  type AuctionStatusMapping = {
    [auctionId: string]: AuctionStatus
  }

  const [auctionStatusMapping, setAuctionStatusMapping] =
    useState<AuctionStatusMapping>({})

  const notificationHandler = (notification: WebSocketNotification) => {
    setAuctionStatusMapping((previousState) => {
      let auctionStatusesCopy = { ...previousState }

      auctionStatusesCopy[notification.auctionId] = notification

      return auctionStatusesCopy
    })
  }

  return (
    <>
      {(isSeller || isDealer) && (
        <Websocket notificationHandler={notificationHandler} />
      )}

      <LiveSalePackagesContent
        pageSize={10}
        auctionStatusMapping={auctionStatusMapping}
      />
      <LiveSaleVehiclesContent
        pageSize={20}
        auctionStatusMapping={auctionStatusMapping}
        withCountdown={false}
      />
    </>
  )
}
