import { useEffect, useState } from 'react'
import { Vehicle } from '../Models/Vehicle'
import { callBackendAPI } from '../api'
import loadEnvironment from '../loadEnvironment'
import {AVAILABLE_MODEL_FILTERS, NO_DERIVATIVE_SELECTED} from '../Components/Filters/FilterConstants'

const environment = loadEnvironment()

export default function useSoldVehicles(
  modelFilter: string,
  derivativeFilter: string[],
  pageIndex: number,
  pageSize: number
) {
  const [vehicles, setVehicles] = useState<Vehicle[]>([])
  const [totalVehicles, setTotalVehicles] = useState(0)

  useEffect(() => {
    let isMounted = true

    const fetchVehicles = async () => {
      let url = `${environment.B2B_BACKEND_BASE_URL}/api/sold-vehicles?page=${pageIndex}&pageSize=${pageSize}`
      if (modelFilter !== AVAILABLE_MODEL_FILTERS[0])
        url += `&models=${modelFilter.toLowerCase()}`
      if (!derivativeFilter.includes(NO_DERIVATIVE_SELECTED)) {
        derivativeFilter.forEach((derivative) => {
          url += `&derivatives=${encodeURIComponent(derivative)}`
        })
      }
      const response = await callBackendAPI(url)
      if (isMounted) {
        setVehicles(response.data.vehicles)
        setTotalVehicles(response.data.total)
      }
    }

    fetchVehicles()

    return () => {
      isMounted = false
    }
  }, [pageIndex, pageSize, modelFilter, derivativeFilter])

  return { vehicles, totalVehicles }
}
