import {
  PButton,
  PButtonPure,
  PHeading,
  PIcon,
  PInlineNotification,
  PSpinner, PTag,
  PText,
} from '@porsche-design-system/components-react'
import useVehicleDetails from '../../../Hooks/useVehicleDetails'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './DetailsPage.module.css'
import tabsBarStyle from '../../TabsBar/TabsBar.module.css'
import React, { useCallback, useContext, useState } from 'react'
import {
  determineRoute,
  determineVehicleStatus,
  VEHICLE_STATUS,
} from '../../../helpers/vehicleStatus'
import { SummaryBlock } from './SummaryBlock'
import { EquipmentBlock } from './EquipmentBlock'
import { ModelInformationBlock } from './ModelInformationBlock'
import { TechnicalDataBlock } from './TechnicalDataBlock'
import { UserInfo } from '../../../App'
import { useTranslation } from 'react-i18next'
import BiddingModal from './BiddingModal'
import usePorscheCenter from '../../../Hooks/usePorscheCenter'
import Websocket from '../../Websocket'
import {
  AuctionStatus,
  WebSocketNotification,
} from '../LiveSaleTab/LiveSaleSingleAuctions/LiveSaleVehiclesContent'
import { useBids } from '../../../Hooks/useBids'
import {
  determinePackageStatus,
  PackageSummaryBlock,
} from './PackageDetailsPage/PackageSummaryBlock'
import { PackageVehicleTabsBar } from './PackageDetailsPage/PackageVehicleTabsBar'
import { DeletePackageModal } from './DeletePackageModal'
import { FavouriteButton } from '../../FavouriteAuction'

const DetailsPage = () => {
  const { t } = useTranslation()
  const params = useParams()

  const [vehicleWithDetails, errorHeading, errorDescription, isLoading] =
    useVehicleDetails(params.vin)

  let navigate = useNavigate()
  const { userId, isDealer, isSeller } = useContext(UserInfo)

  const [isBiddingModalOpen, setIsBiddingModalOpen] = useState(false)
  const [isDeletePackageModalOpen, setIsDeletePackageModalOpen] = useState(false)

  const [auctionStatusMapping, setAuctionStatusMapping] =
    useState<AuctionStatusMapping>({})
  const porscheCenters = usePorscheCenter(userId, isDealer)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bids, _] = useBids()

  const navigateToVehicleList = () => {
    const route = determineRoute(
      vehicleWithDetails?.primary_status,
      vehicleWithDetails?.secondary_status,
      vehicleWithDetails?.blocked_for_sales === true,
      vehicleWithDetails?.auction,
      isSeller,
      isDealer
    )
    navigate(route)
  }

  const status = determineVehicleStatus(
    vehicleWithDetails?.primary_status,
    vehicleWithDetails?.secondary_status,
    Boolean(vehicleWithDetails?.blocked_for_sales),
    vehicleWithDetails?.auction,
    isSeller,
    isDealer
  )

  const handleModalOpen = useCallback(() => {
    setIsBiddingModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setIsBiddingModalOpen(false)
  }, [])

  const scrollToBid = () => {
    const bidButton = document.getElementById('bidButton')
    if (bidButton) {
      bidButton.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const userMaxBid = bids
    .filter((bid) => bid.auction_id === vehicleWithDetails?.auction?._id)
    .sort((bid_a, bid_b) => Number(bid_a.bid_amount) - Number(bid_b.bid_amount))
    .reverse()[0]

  if (!isLoading && vehicleWithDetails == null && t) {
    return (
      <div className={styles.notFoundNotification}>
        <PInlineNotification
          heading={t(errorHeading)}
          description={errorDescription}
          dismissButton={false}
          state={'error'}
        />
      </div>
    )
  }

  type AuctionStatusMapping = {
    [auctionId: string]: AuctionStatus
  }

  // TODO: DRY auction status mapping accross list views and the details page

  const currentHighestBid =
    vehicleWithDetails?.auction &&
    auctionStatusMapping[vehicleWithDetails?.auction?._id]
      ? auctionStatusMapping[vehicleWithDetails?.auction?._id].currentHighestBid
      : Number(vehicleWithDetails?.auction?.current_bid)

  const maxBidId =
    vehicleWithDetails?.auction &&
    auctionStatusMapping[vehicleWithDetails?.auction?._id]
      ? auctionStatusMapping[vehicleWithDetails?.auction?._id].maxBidId
      : vehicleWithDetails?.auction?.max_bid_id

  const totalBids =
    vehicleWithDetails?.auction &&
    auctionStatusMapping[vehicleWithDetails?.auction?._id]
      ? auctionStatusMapping[vehicleWithDetails?.auction?._id].totalBids
      : Number(vehicleWithDetails?.auction?.total_bids)

  const notificationHandler = (notification: WebSocketNotification) => {
    setAuctionStatusMapping((previousState) => {
      let auctionStatusesCopy = { ...previousState }

      auctionStatusesCopy[notification.auctionId] = notification

      return auctionStatusesCopy
    })
  }

  const shouldRenderPackageView =
    determinePackageStatus(vehicleWithDetails?.auction) !== '–'

  const renderVehicleDetails = () => {
    if (isLoading)
      return (
        <div className={styles.spinner}>
          <PSpinner
            size={'inherit'}
            aria={{ 'aria-label': 'loading page content' }}
          />
        </div>
      )

    return (
      <div className={tabsBarStyle.centralizedContent}>
        <SummaryBlock
          vehicleWithDetails={vehicleWithDetails}
          currentHighestBid={currentHighestBid}
          maxBidId={maxBidId}
          userMaxBid={userMaxBid}
        />
        <EquipmentBlock equipment={vehicleWithDetails?.details?.equipment} />
        <ModelInformationBlock vehicleWithDetails={vehicleWithDetails} />
        <TechnicalDataBlock
          technicalData={vehicleWithDetails?.details?.technical_data}
        />
        {status === 'dealerSale' && isDealer && (
          <div className={styles.bidButton}>
            <PButton
              variant="primary"
              type="button"
              aria={{ 'aria-haspopup': 'dialog' }}
              onClick={handleModalOpen}
              id={'bidButton'}
            >
              {t('dealerView.bidButton')}
            </PButton>
            <BiddingModal
              vehicleWithDetails={vehicleWithDetails}
              porscheCenters={porscheCenters}
              open={isBiddingModalOpen}
              closingHandler={handleModalClose}
              currentHighestBid={currentHighestBid}
              maxBidId={maxBidId}
              userMaxBid={userMaxBid}
              isPackageAuction={shouldRenderPackageView}
              isQuickBid={false}
            />
          </div>
        )}

        {isSeller &&
          status === VEHICLE_STATUS.ready &&
          isDeletePackageModalOpen && (
            <DeletePackageModal
              vin={`${params.vin}`}
              packageNumber={Number(
                vehicleWithDetails?.auction?.package_number
              )}
              open={isDeletePackageModalOpen}
              vehicles={vehicleWithDetails?.auction?.vehicles ?? []}
              closingHandler={() => setIsDeletePackageModalOpen(false)}
            />
          )}
      </div>
    )
  }

  const scrollToBidButton = (
    <PButton
      variant="tertiary"
      icon={'arrow-head-down'}
      className={styles.detailsPageUpperRightButton}
      onClick={() => {
        scrollToBid()
      }}
    >
      {t('dealerView.goToBidButton')}
    </PButton>
  )

  const deletePackageButton = (
    <PButton
      data-testid="delete-package-button"
      variant="primary"
      icon={'delete'}
      className={styles.detailsPageUpperRightButton}
      onClick={() => {
        setIsDeletePackageModalOpen(true)
      }}
    >
      {t('sellerView.deletePackageButton')}
    </PButton>
  )

  return (
    <>
      {(isSeller || isDealer) && (
        <Websocket notificationHandler={notificationHandler} />
      )}
      <div
        className={`${tabsBarStyle.whiteBackground} ${
          shouldRenderPackageView && tabsBarStyle.packageAuctionButtonAlignment
        }`}
      >
        <PButtonPure
          className={styles.backButton}
          onClick={() => {
            !isLoading && navigateToVehicleList()
          }}
          icon="arrow-head-left"
        >
          {t('sharedView.backToTheListView')}
        </PButtonPure>
        
        {!isLoading && 
          (shouldRenderPackageView ? (
            <div>
              {isDealer && vehicleWithDetails?.auction && (
                  <FavouriteButton auction={vehicleWithDetails.auction} variant='button'/>
              )}
              {isDealer && status === 'dealerSale' ? (
                scrollToBidButton
              ) : (
                VEHICLE_STATUS.ready === status &&
                deletePackageButton
              )}
            </div>
          ) : (
            <div className={styles.detailHeaderRow}>
              <div>
                <div className={styles.detailHeadlineWrapper} data-testid="details-page-heading">
                  <PHeading
                    tag="h3"
                    size="large"
                  >
                    {vehicleWithDetails?.derivative}
                  </PHeading>
                  <div className={styles.iconsWrapper}>
                    {isSeller && vehicleWithDetails?.showroom ? (<PIcon name="home" size="small"></PIcon>) : (<></>)}
                    {isSeller && vehicleWithDetails?.reserved ? (<PIcon name="user" size="small"></PIcon>) : (<></>)}
                    {isSeller && vehicleWithDetails?.crash ? (<PIcon name="wrenches" size="small"></PIcon>) : (<></>)}
                    {vehicleWithDetails?.extra_tyres ? (<PTag icon="snowflake">
                      {t('sharedView.extraTyres')}
                    </PTag>) : (<></>)}
                  </div>
                </div>

                <PText className={styles.paddingBottom}>
                  {' '}
                  {vehicleWithDetails?.vin}{' '}
                </PText>
              </div>
              <div>
                {isDealer && vehicleWithDetails?.auction && (
                    <FavouriteButton auction={vehicleWithDetails.auction} variant='button'/>
                )}
                {status === 'dealerSale' && isDealer && scrollToBidButton}
              </div>
            </div>
          ))}
      </div>    
      {shouldRenderPackageView && (
        <div className={tabsBarStyle.centralizedContent}>
          <div className={styles.detailBlock}>
            <div>
              <PackageSummaryBlock
                vehicleWithDetails={vehicleWithDetails}
                currentHighestBid={currentHighestBid}
                maxBidId={maxBidId}
                userMaxBid={userMaxBid}
                totalBids={totalBids}
              />
            </div>
          </div>
          <div className={styles.packageTab}>
            <div>
              <PackageVehicleTabsBar
                vehicleWithDetails={vehicleWithDetails}
                currentVin={params.vin}
              />
            </div>
          </div>
        </div>
      )}
      {renderVehicleDetails()}
    </>
  )
}

export default DetailsPage
