import { PSelectWrapper } from '@porsche-design-system/components-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AVAILABLE_MODEL_FILTERS } from './FilterConstants'
import styles from './FilterRow.module.css'

const SelectModel = (props: { onChange: Function; modelFilter: string }) => {
  const { t } = useTranslation()

  const selectOptions = AVAILABLE_MODEL_FILTERS.map((modelFilter) => (
    <option key={modelFilter} value={modelFilter}>
      {t(modelFilter)}
    </option>
  ))

  const changeModelFilter = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      props.onChange(event.target.value)
    },
    [props]
  )

  return (
    <>
      <PSelectWrapper className={styles.selectFilter}>
        <select
          name="SelectModel"
          aria-label="Model filter"
          onChange={changeModelFilter}
          value={props.modelFilter}
        >
          {selectOptions}
        </select>
      </PSelectWrapper>
    </>
  )
}

export default SelectModel
