import { NumberFormatValues } from 'react-number-format'
import { convertUtcTimeToGermanTime } from './helperFunctions'

export const validInitialPrice = (initialPrice: NumberFormatValues) => {
  return (initialPrice.floatValue ?? 1) > 0
}

export const validStartDate = (startDate: string) => {
  return (
    startDate === '' ||
    convertUtcTimeToGermanTime(new Date(startDate)) >
      convertUtcTimeToGermanTime(new Date())
  )
}

export const startDateIsNotOnWeekend = (startDate: string) => {
  const dayOfWeek = convertUtcTimeToGermanTime(new Date(startDate)).getDay()
  return dayOfWeek !== 0 && dayOfWeek !== 6
}
