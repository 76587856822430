import {PTextFieldWrapper} from '@porsche-design-system/components-react'
import React, { useCallback } from 'react'
import styles from './FilterRow.module.css'
import { useTranslation } from 'react-i18next'
import {startDateIsNotOnWeekend, validStartDate} from "../../helpers/formValidations";

const SelectAuctionBundleStatus = (props: {
  onChange: Function
  dateFilter: string
}) => {
  const { t } = useTranslation()
  const changeDate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange(event.target.value &&
        new Date(event.target.value).toISOString().split('T')[0])
    },
    [props]
  )

  return (
    <PTextFieldWrapper
      hideLabel={true}
      state={
        (props.dateFilter !== '' &&
          !validStartDate(props.dateFilter)) ||
        !startDateIsNotOnWeekend(props.dateFilter)
          ? 'error'
          : 'none'
      }
      message={t(
        'sellerView.dateMustBeInTheFutureAndNotOnAWeekend'
      ).toString()}
      className={styles.selectFilter}
    >
      <input
        type="date"
        name="start-date"
        aria-label="start date"
        value={props.dateFilter}
        onChange={changeDate}
      />
    </PTextFieldWrapper>
  )
}

export default SelectAuctionBundleStatus
