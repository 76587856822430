import React, { useRef, useState } from 'react'
import useAccessToken from '../Hooks/useAccessToken'
// @ts-ignore
import SockJsClient from 'react-stomp'
import loadEnvironment from '../loadEnvironment'

const environment = loadEnvironment()
const socketConnectionURL = `${environment.B2B_BACKEND_BASE_URL}/ws-notifications`
const Websocket = (props: { notificationHandler: Function }) => {
  const [sockJsKey, setSockJsKey] = useState(0)
  const accessToken = useAccessToken(sockJsKey)

  const sockJsRef = useRef()

  return (
    <>
      {accessToken && (
        <SockJsClient
          url={`${socketConnectionURL}?token=Bearer%20${accessToken}`}
          topics={['/topic/auction-status']}
          onMessage={props.notificationHandler}
          onConnectFailure={() => {
            // @ts-ignore
            sockJsRef.current.disconnect()
            setSockJsKey(sockJsKey + 1)
          }}
          ref={sockJsRef}
          key={sockJsKey}
        />
      )}
    </>
  )
}

export default Websocket
