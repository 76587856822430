import { Vehicle } from '../../../../Models/Vehicle'
import {
  PTabsBar,
  TabsBarUpdateEvent,
} from '@porsche-design-system/components-react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const PackageVehicleTabsBar = (props: {
  vehicleWithDetails: Vehicle | null
  currentVin: string | undefined
}) => {
  const { vehicleWithDetails, currentVin } = props
  const vehicles = vehicleWithDetails?.auction?.vehicles?.sort((a, b) =>
    a.vin.localeCompare(b.vin)
  )

  const [tabIndex, setTabIndex] = useState(
    vehicles?.findIndex((it) => it.vin === currentVin)
  )
  let navigate = useNavigate()

  const onTabUpdate = (e: CustomEvent<TabsBarUpdateEvent>) => {
    const newTabIndex = e.detail.activeTabIndex
    setTabIndex(newTabIndex)
    if (vehicles) {
      const newVin = vehicles[newTabIndex].vin
      navigate(`/details/${newVin}`)
    }
  }
  return (
    <>
      <PTabsBar
        activeTabIndex={tabIndex}
        size={'medium'}
        onUpdate={onTabUpdate}
      >
        {vehicles?.map((vehicle, i) => (
          <button type={'button'} key={i}>
            {vehicle.derivative}
          </button>
        ))}
      </PTabsBar>
    </>
  )
}
