import styles from './DetailsPage.module.css'
import React from 'react'
import { Gallery } from '../../../Models/Vehicle'

export const getImg = (src?: string, ariaLabel?: string) => {
  return (
    <img
      className={src ? styles.galleryImg : styles.galleryFallbackImg}
      src={src || '/Car-Preview.png'}
      alt="car preview"
      aria-label={ariaLabel}
    />
  )
}

  export const ImageGallery = (props: { imgGallery?: Gallery }) => {
  return (
    <div className={styles.gallery}>
      {getImg(props.imgGallery?.extcam1, 'exterior image 1')}
      {getImg(props.imgGallery?.extcam2, 'exterior image 2')}
      {getImg(props.imgGallery?.extcam3, 'exterior image 3')}
      {getImg(props.imgGallery?.intcam1, 'interior image 1')}
    </div>
  )
}
