import React, { useCallback, useEffect, useState } from 'react'
import styles from '../../IncomingTab/IncomingVehiclesContent.module.css'
import {
  PaginationUpdateEvent,
  PPagination, PPopover,
  PTable,
  PTableBody,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PText,
} from '@porsche-design-system/components-react'
import ReadyForSalePackageRow from './ReadyForSalePackageRow'
import { useTranslation } from 'react-i18next'
import usePackageAuctions, {
  TAB_READY_FOR_SALES,
} from '../../../../Hooks/usePackageAuctions'

export default function ReadyForSalePackagesContent(props: {
  pageSize: number
}) {
  const { t } = useTranslation()
  const [pageIndex, setPageIndex] = useState(1)

  const { auctions, totalAuctions } = usePackageAuctions(
    TAB_READY_FOR_SALES,
    pageIndex
  )

  const onUpdate = useCallback((e: CustomEvent<PaginationUpdateEvent>) => {
    setPageIndex(e.detail.page)
  }, [])

  useEffect(() => setPageIndex(1), [])

  return (
    <div className={styles.packagesContent}>
      <PText className={styles.title} size="medium" weight="semi-bold">
        {t('sharedView.packageAuction')}
      </PText>
      <PText className={styles.listSize} size="small" weight="semi-bold">
        {t('sharedView.packageCount', { count: totalAuctions })}
      </PText>
      {totalAuctions !== 0 && (
        <PTable caption="Some caption">
          <PTableHead>
            <PTableHeadRow>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">
                  {t('sharedView.vehiclePictures')}
                </PText>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">{t('sharedView.packageNumber')}</PText>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">
                  {t('sharedView.numberOfVehicles')}
                </PText>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                <div className={styles.inlinePopoverContainer}>
                  <PText weight="semi-bold">
                    {t('sharedView.vehicleInitialPrice')}
                  </PText>
                  <PPopover
                      className={styles.popover}
                      aria={{ 'aria-label': 'Initial price additional information' }}
                  >
                    {t('sharedView.vehicleNettoPrice')}
                  </PPopover>
                </div>
              </PTableHeadCell>
              <PTableHeadCell role="columnheader">
                <PText weight="semi-bold">
                  {t('sharedView.auctionStartDate')}
                </PText>
              </PTableHeadCell>
            </PTableHeadRow>
          </PTableHead>
          <PTableBody>
            {auctions?.map((item, i) => (
              <ReadyForSalePackageRow key={i} auction={item} />
            ))}
          </PTableBody>
        </PTable>
      )}
      {totalAuctions > props.pageSize && (
        <PPagination
          className={styles.pagination}
          totalItemsCount={totalAuctions}
          itemsPerPage={props.pageSize}
          activePage={pageIndex}
          onUpdate={onUpdate}
        />
      )}
    </div>
  )
}
