import React from 'react'
import ReadyForSaleVehiclesContent from './ReadyForSaleSingleAuctions/ReadyForSaleVehiclesContent'
import ReadyForSalePackagesContent from './ReadyForSalePackageAuctions/ReadyForSalePackagesContent'

export default function ReadyForSaleTab() {
  return (
    <>
      <ReadyForSalePackagesContent pageSize={10} />
      <ReadyForSaleVehiclesContent pageSize={20} />
    </>
  )
}
