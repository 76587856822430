import { useEffect, useState } from 'react'
import { getAccessToken } from '../api'

export default function useAccessToken(sockJsKey: number) {
  const [accessToken, setAccessToken] = useState('')

  useEffect(() => {
    let isMounted = true
    const getEffectAccessToken = async () => {
      const token = await getAccessToken()
      if (isMounted) {
        setAccessToken(token)
      }
    }

    getEffectAccessToken()

    return () => {
      isMounted = false
    }
  }, [sockJsKey])

  return accessToken
}
