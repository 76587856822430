import {
  PButtonPure, PIcon,
  PPopover,
  PTableCell,
  PTableRow,
  PText,
} from '@porsche-design-system/components-react'
import styles from '../../IncomingTab/IncomingVehiclesContent.module.css'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Vehicle } from '../../../../Models/Vehicle'
import { getStartingDays } from '../../ReadyForSaleTab/ReadyForSaleSingleAuctions/ReadyForSaleVehicleRow'
import { useTranslation } from 'react-i18next'
import {
  kilometerFormatting,
  priceFormatting,
} from '../../../../helpers/helperFunctions'
import { FavouriteTableCell } from '../../../FavouriteAuction'


export function getDate(dateType?: string) {
  return dateType === undefined || dateType === null
    ? '—'
    : new Date(Date.parse('' + dateType)).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
}

const PreviewVehicleRow = (props: { vehicle: Vehicle }) => {
  let navigate = useNavigate()
  const { t } = useTranslation()

  const navigateToDetails = () => {
    navigate(`/details/${props.vehicle.vin}`)
  }

  return (
    <PTableRow role="row">
      <PTableCell>
        <PButtonPure
          icon="none"
          onClick={navigateToDetails}
          aria-label="open vehicle details"
        >
          <img
            className={styles.image}
            src={props.vehicle.preview || '/Car-Preview.png'}
            alt="car preview"
          />
        </PButtonPure>
      </PTableCell>
      <PTableCell>
        {props.vehicle.derivative ? (
          <PButtonPure weight="bold" icon="none" onClick={navigateToDetails}>
            <u>{props.vehicle.derivative}</u>
            {props.vehicle.extra_tyres ? (<PIcon name="snowflake" size="small"></PIcon>) : (<></>)}
          </PButtonPure>
        ) : (
          <PText>{'–'}{props.vehicle.extra_tyres ? (<PIcon name="snowflake" size="small"></PIcon>) : (<></>)}</PText>
        )}
        <PText>{props.vehicle.vin}</PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {props.vehicle.exterior_color?.name}
          <PPopover
            className={styles.popover}
            direction="top"
            description={props.vehicle.exterior_color?.description}
          />
        </PText>
        <PText>
          {props.vehicle.interior_color?.name}
          <PPopover
            className={styles.popover}
            direction="top"
            description={props.vehicle.interior_color?.description}
          />
        </PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {getDate(props.vehicle.date_of_first_registration) || '—'}
        </PText>
        <PText>
          {(props.vehicle.mileage &&
            kilometerFormatting(props.vehicle.mileage)) ||
            '—'}
        </PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {priceFormatting(Number(props.vehicle.auction?.initial_price))}
        </PText>
        <div className={styles.returnedDateAndDays}>
          <PText>{getDate(props.vehicle.auction?.start_date)}</PText>
          {getDate(props.vehicle.auction?.start_date) !== '—' && (
            <PText className={styles.returnedDays}>
              {getStartingDays(props.vehicle) === '0'
                ? `(${t('sharedView.auctionStartToday')})`
                : getStartingDays(props.vehicle) === '1'
                ? `(${t('sharedView.auctionStartTomorrow')})`
                : `(${t('sharedView.auctionStartDatePrefix')}${getStartingDays(
                    props.vehicle
                  )} ${t('sharedView.auctionStartDateSuffix')})`}
            </PText>
          )}
        </div>
      </PTableCell>
      {props.vehicle.auction && (
          <FavouriteTableCell auction={props.vehicle.auction} />
      )}
    </PTableRow>
  )
}

export default PreviewVehicleRow
