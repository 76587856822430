import {
  PButtonPure,
  PTableCell,
  PTableRow,
  PText,
} from '@porsche-design-system/components-react'
import styles from '../../IncomingTab/IncomingVehiclesContent.module.css'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getStartingDays } from '../../ReadyForSaleTab/ReadyForSalePackageAuctions/ReadyForSalePackageRow'
import { useTranslation } from 'react-i18next'
import { priceFormatting } from '../../../../helpers/helperFunctions'
import { Auction } from '../../../../Models/Auction'
import PackageVehicleImages from '../../DetailsPage/PackageDetailsPage/PackageVehicleImages'
import { FavouriteTableCell } from '../../../FavouriteAuction'


export function getDate(dateType?: string) {
  return dateType === undefined || dateType === null
    ? '—'
    : new Date(Date.parse('' + dateType)).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
}

const PreviewPackageRow = (props: { auction: Auction }) => {
  let navigate = useNavigate()
  const { t } = useTranslation()

  const { auction } = props

  const navigateToDetails = () => {
    if (auction?.vins === undefined || auction?.vins === null) return

    navigate(`/details/${auction?.vins.sort()[0]}`)
  }

  return (
    <PTableRow role="row">
      <PTableCell>
        <PButtonPure
          icon="none"
          onClick={navigateToDetails}
          aria-label="open vehicle details"
        >
          <PackageVehicleImages vehicles={auction.vehicles} />
        </PButtonPure>
      </PTableCell>
      <PTableCell>
        <PButtonPure weight={'bold'} icon={'none'} onClick={navigateToDetails}>
          <u>{props.auction.package_number}</u>
        </PButtonPure>
      </PTableCell>
      <PTableCell>
        <PText>{props.auction.vehicles?.length || 0}</PText>
      </PTableCell>
      <PTableCell>
        <PText>{priceFormatting(Number(props.auction.initial_price))}</PText>
      </PTableCell>
      <PTableCell>
        <div className={styles.returnedDateAndDays}>
          <PText>{getDate(props.auction.start_date)}</PText>
          {getDate(props.auction.start_date) !== '—' && (
            <PText className={styles.returnedDays}>
              {getStartingDays(props.auction) === '0'
                ? `(${t('sharedView.auctionStartToday')})`
                : getStartingDays(props.auction) === '1'
                ? `(${t('sharedView.auctionStartTomorrow')})`
                : `(${t('sharedView.auctionStartDatePrefix')}${getStartingDays(
                    props.auction
                  )} ${t('sharedView.auctionStartDateSuffix')})`}
            </PText>
          )}
        </div>
      </PTableCell>
      <FavouriteTableCell auction={props.auction}/>
    </PTableRow>
  )
}

export default PreviewPackageRow
