import {
  PButtonPure,
  PTableCell,
  PTableRow,
  PText,
} from '@porsche-design-system/components-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { priceFormatting } from '../../../../helpers/helperFunctions'
import { Auction } from '../../../../Models/Auction'
import PackageVehicleImages from '../../DetailsPage/PackageDetailsPage/PackageVehicleImages'

export function getDate(dateType?: string) {
  return dateType === undefined || dateType === null
    ? '—'
    : new Date(Date.parse('' + dateType)).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
}

export function getStartingDays(item: Auction) {
  return item.start_date === undefined || item.start_date === null
    ? ''
    : (
        (Date.parse(item.start_date) - Date.now()) /
        (1000 * 60 * 60 * 24)
      ).toFixed()
}

const ReadyForSalePackageRow = (props: { auction: Auction }) => {
  let navigate = useNavigate()
  const { t } = useTranslation()

  const { auction } = props
  const navigateToDetails = () => {
    if (auction?.vins === undefined || auction?.vins === null) return

    navigate(`/details/${auction?.vins.sort()[0]}`) // auction will not be based on a single vin anymore...
  }

  return (
    <PTableRow role="row">
      <PTableCell>
        <PButtonPure
          icon="none"
          onClick={navigateToDetails}
          aria-label="open vehicle details"
        >
          <PackageVehicleImages vehicles={auction.vehicles} />
        </PButtonPure>
      </PTableCell>
      <PTableCell>
        <PButtonPure weight="bold" icon="none" onClick={navigateToDetails}>
          <PText>{auction.package_number}</PText>
        </PButtonPure>
      </PTableCell>
      <PTableCell>
        <PText>{auction.vins?.length || 0}</PText>
      </PTableCell>
      <PTableCell>
        <PText>{priceFormatting(Number(auction.initial_price))}</PText>
      </PTableCell>
      <PTableCell>
        <div>
          <PText>{getDate(auction.start_date)}</PText>
          {getDate(auction.start_date) !== '—' && (
            <PText>
              {getStartingDays(auction) === '0'
                ? `(${t('sharedView.auctionStartToday')})`
                : getStartingDays(auction) === '1'
                ? `(${t('sharedView.auctionStartTomorrow')})`
                : `(${t('sharedView.auctionStartDatePrefix')}${getStartingDays(
                    auction
                  )} ${t('sharedView.auctionStartDateSuffix')})`}
            </PText>
          )}
        </div>
      </PTableCell>
    </PTableRow>
  )
}

export default ReadyForSalePackageRow
