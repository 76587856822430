import {
  PButtonPure,
  PTableCell,
  PTableRow,
  PTag,
  PText,
} from '@porsche-design-system/components-react'
import styles from '../IncomingTab/IncomingVehiclesContent.module.css'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Vehicle } from '../../../Models/Vehicle'
import { UserInfo } from '../../../App'
import { priceFormatting } from '../../../helpers/helperFunctions'
import { getDate } from '../LiveSaleTab/LiveSaleSingleAuctions/LiveSaleVehicleRow'
import { useTranslation } from 'react-i18next'

const SoldVehicleRow = (props: { vehicle: Vehicle }) => {
  const { t } = useTranslation()
  const userInfo = useContext(UserInfo)
  let navigate = useNavigate()
  const { isDealer } = useContext(UserInfo)

  const navigateToDetails = () => {
    navigate(`/details/${props.vehicle.vin}`)
  }

  return (
    <PTableRow role="row">
      <PTableCell>
        {isDealer ? (
          <PButtonPure
            icon="none"
            onClick={navigateToDetails}
            aria-label="open vehicle details"
          >
            <img
              className={styles.image}
              src={props.vehicle.preview || '/Car-Preview.png'}
              alt="car preview"
            />
          </PButtonPure>
        ) : (
          <img
            className={styles.image}
            src={props.vehicle.preview || '/Car-Preview.png'}
            alt="car preview"
          />
        )}
      </PTableCell>
      <PTableCell>
        {props.vehicle.derivative ? (
          isDealer ? (
            <PButtonPure icon="none" onClick={navigateToDetails}>
              <u>{props.vehicle.derivative}</u>
            </PButtonPure>
          ) : (
            <u>{props.vehicle.derivative}</u>
          )
        ) : (
          <PText>{'–'}</PText>
        )}
        <PText>{props.vehicle.vin}</PText>
      </PTableCell>
      <PTableCell>
        {props.vehicle.auction?.winner_porsche_center_name}
      </PTableCell>
      <PTableCell>
        <PText>
          {priceFormatting(
            props.vehicle.bundle_auction
              ? (Number(props.vehicle.auction?.current_bid) *
                  Number(props.vehicle.initial_price)) /
                  Number(props.vehicle.auction?.sum_vehicle_prices || props.vehicle.auction?.initial_price)
              : Number(props.vehicle.auction?.current_bid)
          )}
        </PText>
        <PText>{getDate(props.vehicle.auction?.end_date)}</PText>
      </PTableCell>
      <PTableCell>
        <PText>
          {userInfo.isSeller ? (
            <PTag color="notification-success-soft">
              {t('sellerView.readyForInvoicingStatus')}
            </PTag>
          ) : props.vehicle.secondary_status === 'QS20' ? (
            <PTag color="background-surface">
              {t('dealerView.inProcessStatus')}
            </PTag>
          ) : props.vehicle.secondary_status === 'QS30' ? (
            <PTag color="notification-info-soft">
              {t('dealerView.invoicedStatus')}
            </PTag>
          ) : (
            <PTag color="notification-success-soft">
              {t('dealerView.handedOverStatus')}
            </PTag>
          )}
        </PText>
      </PTableCell>
    </PTableRow>
  )
}

export default SoldVehicleRow
