import { PSelectWrapper } from '@porsche-design-system/components-react'
import React, { useCallback } from 'react'
import styles from './FilterRow.module.css'
import { useTranslation } from 'react-i18next'

const SelectAuctionBundleStatus = (props: {
  onChange: Function
  bundleFilter: string
}) => {
  const { t } = useTranslation()
  const changeProcessStatus = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      props.onChange(event.target.value)
    },
    [props]
  )

  return (
    <PSelectWrapper className={styles.selectFilter} hideLabel={true}>
      <select
        onChange={changeProcessStatus}
        name="SelectAuctionBundleStatus"
        aria-label="Auction bundle status filter"
        value={props.bundleFilter}
      >
        <option key={'allAuctions'} value={''}>
          {t('sharedView.allAuctions')}
        </option>
        <option key={'singleAuction'} value={'SINGLE'}>
          {t('sharedView.singleAuction')}
        </option>
        <option key={'packageAuction'} value={'PACKAGE'}>
          {t('sharedView.packageAuction')}
        </option>
        <option key={'newVehicles'} value={'NEW'}>
          {t('sharedView.newVehicles')}
        </option>
      </select>
    </PSelectWrapper>
  )
}

export default SelectAuctionBundleStatus
