import React, { useEffect, useState } from 'react'
import { PText } from '@porsche-design-system/components-react'
import styles from './AuctionCountdown.module.css'
import { Auction } from '../Models/Auction'
import {
  convertUtcTimeToGermanTime,
  isAuctionLive,
} from '../helpers/helperFunctions'
import { useTranslation } from 'react-i18next'
import loadEnvironment from '../loadEnvironment'
import { getTimezoneOffset } from 'date-fns-tz'
import {
  addDays,
  intervalToDuration,
  isWeekend,
  millisecondsToHours,
  nextMonday,
} from 'date-fns'

const environment = loadEnvironment()

export type TimeLeft = {
  hours: number
  minutes: number
  seconds: number
}
export const calculateAuctionEnd = (auction: Auction): string => {
  if (
    auction.start_date === null ||
    auction.start_date === '' ||
    auction.end_date === null ||
    auction.end_date === ''
  )
    return 'sharedView.auctionWithoutDate'
  const date = convertUtcTimeToGermanTime(new Date())
  const start_date = convertUtcTimeToGermanTime(new Date(auction.start_date))
  const end_date = convertUtcTimeToGermanTime(new Date(auction.end_date))

  if (end_date < date) {
    return 'sharedView.auctionHasEnded'
  } else if (start_date > date) {
    return 'sharedView.auctionStartsSoon'
  } else {
    const timeLeft = calculateTimeLeft()
    const minutesLeft = timeLeft.hours * 60 + timeLeft.minutes
    if (minutesLeft > 0) {
      return `in ${timeLeft.hours * 60 + timeLeft.minutes} min.`
    } else return '<1 min.'
  }
}

export const calculateTimeLeft = (): TimeLeft => {
  const { AUCTION_START_TIME, AUCTION_END_TIME } = environment

  const dateGermany = convertUtcTimeToGermanTime(new Date())
  const year = dateGermany.getFullYear()
  const month = dateGermany.getMonth()
  const dayOfMonth = dateGermany.getDate()

  const offsetGermany = `0${millisecondsToHours(
    getTimezoneOffset('Europe/Berlin')
  )}:00`

  const dateString = `${year}-${String(month + 1).padStart(2, '0')}-${String(
    dayOfMonth
  ).padStart(2, '0')}`

  const startDateString = `${dateString}T0${AUCTION_START_TIME}:00:00+${offsetGermany}`
  let auctionStartDate = convertUtcTimeToGermanTime(new Date(startDateString))

  const endDateString = `${dateString}T${AUCTION_END_TIME}:00:00+${offsetGermany}`
  const auctionEndDate = convertUtcTimeToGermanTime(new Date(endDateString))

  let dateToCountDown = auctionEndDate

  let considerWeekend = false
  if (
    isWeekend(auctionStartDate) ||
    (isWeekend(addDays(dateGermany, 1)) &&
      dateGermany.getHours() >= Number(AUCTION_END_TIME))
  ) {
    auctionStartDate = nextMonday(auctionStartDate)
    considerWeekend = true
  }

  if (dateGermany.getHours() >= Number(AUCTION_END_TIME)) {
    if (considerWeekend) dateToCountDown = auctionStartDate
    else dateToCountDown = addDays(auctionStartDate, 1)
  } else if (dateGermany.getHours() < Number(AUCTION_START_TIME)) {
    dateToCountDown = auctionStartDate
  }

  const duration = intervalToDuration({
    start: +dateGermany,
    end: +dateToCountDown,
  })

  return {
    hours: (duration.days ?? 0) * 24 + (duration.hours ?? 0),
    minutes: duration.minutes ?? 0,
    seconds: duration.seconds ?? 0,
  }
}

interface Props {
  className?: string
}

export const AuctionCountdown: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  return (
    <div className={props.className}>
      <PText>
        {' '}
        {isAuctionLive()
          ? t('sharedView.currentAuctionCountdown')
          : t('sharedView.nextAuctionCountdown')}{' '}
      </PText>

      <div className={styles.countDown}>
        <div className={styles.counterWrapper}>
          <PText
            size={'large'}
            weight={'bold'}
            aria-label={'Auktions Countdown Stunden'}
            className={styles.counterNumber}
          >
            {String(timeLeft.hours).padStart(2, '0')}
          </PText>
          <PText size={'x-small'}>{t('sharedView.countdownHours')}</PText>
        </div>
        <PText size={'large'} weight={'bold'} className={styles.separator}>
          :
        </PText>

        <div className={styles.counterWrapper}>
          <PText
            size={'large'}
            weight={'bold'}
            aria-label={'Auktions Countdown Minuten'}
            className={styles.counterNumber}
          >
            {String(timeLeft.minutes).padStart(2, '0')}
          </PText>
          <PText size={'x-small'}>{t('sharedView.countdownMinutes')}</PText>
        </div>
        <PText size={'large'} weight={'bold'} className={styles.separator}>
          :
        </PText>

        <div className={styles.counterWrapper}>
          <PText
            size={'large'}
            weight={'bold'}
            aria-label={'Auktions Countdown Sekunden'}
            className={styles.counterNumber}
          >
            {String(timeLeft.seconds).padStart(2, '0')}
          </PText>
          <PText size={'x-small'}>{t('sharedView.countdownSeconds')}</PText>
        </div>
      </div>
    </div>
  )
}
