import React, { useCallback, useEffect, useState } from 'react'
import styles from '../IncomingTab/IncomingVehiclesContent.module.css'
import './ShoppingCart.css'
import {
  PButton,
  PButtonGroup,
  PIcon,
  PPopover,
  PScroller,
  PSpinner,
  PTable,
  PTableBody,
  PTableHead,
  PTableHeadCell,
  PTableHeadRow,
  PText,
} from '@porsche-design-system/components-react'
import ReturnedVehicleRow from './ReturnedVehicleRow'
import {
  SECONDARY_STATUSES,
  STATUS_FILTER,
} from '../../../Hooks/statusFilterConstants'
import {
  AVAILABLE_DERIVATIVE_FILTERS,
  AVAILABLE_MODEL_FILTERS,
  NO_DERIVATIVE_SELECTED,
} from '../../Filters/FilterConstants'
import useInfiniteVehicles from '../../../Hooks/useInfiniteVehicles'
import ReturnedVehiclesFilterRow from '../../Filters/ReturnedVehiclesFilterRow'
import { useTranslation } from 'react-i18next'
import { Vehicle } from '../../../Models/Vehicle'
import PackageModal from './PackageModal'
import {
  readFromSessionStorage,
  useSessionStorage,
} from '../../../Hooks/filterHooks'

const primaryStatusFilter = [STATUS_FILTER[3]]
const secondaryStatusFilter = SECONDARY_STATUSES.NO_PRE_CONTRACTS
const futureAuctionsFilter = false
const ignoreSoldCars = false

export default function ReturnedVehiclesContent(props: {
  blockedForSalesFilter: boolean
  pageSize: number
}) {
  const blockedForSales = props.blockedForSalesFilter
  const { t } = useTranslation()
  const [vinSearchInput, setVinSearchInput] = useState(
    readFromSessionStorage('vinSearchInput', ''),
  )
  useSessionStorage(vinSearchInput, 'vinSearchInput')
  const [modelFilter, setModelFilter] = useState(
    readFromSessionStorage(
      'modelFilter',
      AVAILABLE_MODEL_FILTERS[0],
      (x: string) => AVAILABLE_MODEL_FILTERS.includes(x),
    ),
  )
  useSessionStorage(modelFilter, 'modelFilter')
  const [derivativeFilter, setDerivativeFilter] = useState(
    readFromSessionStorage(
      'derivativeFilter',
      NO_DERIVATIVE_SELECTED,
      (x: string) => x.split(',').find(z => AVAILABLE_DERIVATIVE_FILTERS.includes(z)) !== undefined
    ).split(','),
  )
  useSessionStorage(derivativeFilter.toString(), 'derivativeFilter')
  const [auctionBundleStatus, setAuctionBundleStatus] = useState(
    readFromSessionStorage('auctionBundleStatus', '', (x: string) =>
      ['', 'SINGLE', 'PACKAGE', 'NEW'].includes(x),
    ),
  )
  useSessionStorage(auctionBundleStatus, 'auctionBundleStatus')
  const [pageIndex, setPageIndex] = useState(1)

  const { vehicles, totalVehicles, nextPage } = useInfiniteVehicles(modelFilter, derivativeFilter, primaryStatusFilter, secondaryStatusFilter, props.blockedForSalesFilter, futureAuctionsFilter, ignoreSoldCars, pageIndex, props.pageSize, vinSearchInput, props.blockedForSalesFilter ? '' : auctionBundleStatus)

  const handleScroll = useCallback(() => {
    const e = document.documentElement
    if (e.scrollTop + e.clientHeight >= e.scrollHeight - 300) {
      pageIndex !== nextPage &&
        totalVehicles - props.pageSize * pageIndex > 0 &&
        setPageIndex(nextPage)
    }
  }, [props.pageSize, pageIndex, nextPage, totalVehicles, setPageIndex])

  useEffect(() => {
    const delay = window.setTimeout(handleScroll, 100)
    return () => window.clearTimeout(delay)
  }, [vehicles, handleScroll])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const [selectedVehicles, setSelectedVehicles] = useState<Vehicle[]>(
    JSON.parse(`${localStorage.getItem('bundleVehicles')}`) || [],
  )

  const isSelected = (vin: string) => {
    return selectedVehicles?.some((vehicle) => vehicle.vin === vin)
  }

  const selectVehicle = (vehicle: Vehicle) => {
    let vehicles = selectedVehicles.concat(vehicle)
    localStorage.setItem('bundleVehicles', JSON.stringify(vehicles))
    setSelectedVehicles(vehicles)
  }

  const deselectVehicle = (vin: string) => {
    let vehicles = selectedVehicles.filter((vehicle) => vehicle.vin !== vin)
    localStorage.setItem('bundleVehicles', JSON.stringify(vehicles))
    setSelectedVehicles(vehicles)
  }

  const deselectAllVehicles = () => {
    localStorage.removeItem('bundleVehicles')
    setSelectedVehicles([])
  }

  // TODO: Extract code below
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const openModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  useEffect(() => {
    setPageIndex(1)
  }, [
    vinSearchInput,
    modelFilter,
    derivativeFilter,
    auctionBundleStatus,
    props.blockedForSalesFilter,
  ])

  return (
    <div className={styles.returnedVehiclesContainer}>
      <PText className={styles.listSize} size="small" weight="semi-bold">
        {t('sharedView.vehicleCount', { count: totalVehicles })}
      </PText>
      <ReturnedVehiclesFilterRow
        blockedForSales={blockedForSales}
        onChangeVinSearchInput={setVinSearchInput}
        vinSearchInput={vinSearchInput}
        onChangeModel={setModelFilter}
        modelFilter={modelFilter}
        onChangeDerivative={setDerivativeFilter}
        derivativeFilter={derivativeFilter}
        onChangeSelectAuctionBundleStatus={setAuctionBundleStatus}
        selectAuctionBundleStatus={auctionBundleStatus}
      />
      {vinSearchInput !== '' && vehicles?.length === 0 ? (
        <PText className={styles.listSize} size="small">
          {t('sellerView.vinSearchEmptyResultsStart')}
          <strong>"{vinSearchInput}"</strong>
          {t('sellerView.vinSearchEmptyResultsEnd')}
        </PText>
      ) : (
        <>
          {totalVehicles !== 0 && (
            <>
              <PTable caption="Some caption" className={styles.contentTable}>
                <PTableHead>
                  <PTableHeadRow>
                    <PTableHeadCell role="columnheader">
                      <PText weight="semi-bold">
                        {t('sharedView.vehiclePictures')}
                      </PText>
                    </PTableHeadCell>
                    <PTableHeadCell role="columnheader">
                      <PText weight="semi-bold">
                        {t('sharedView.vehicleModel')}
                      </PText>
                      <PText weight="semi-bold">
                        {t('sharedView.vehicleIdentificator')}
                      </PText>
                    </PTableHeadCell>
                    <PTableHeadCell role="columnheader">
                      <PText weight="semi-bold">
                        {t('sharedView.vehicleExteriorColor')}
                      </PText>
                      <PText weight="semi-bold">
                        {t('sharedView.vehicleInteriorColor')}
                      </PText>
                    </PTableHeadCell>
                    <PTableHeadCell role="columnheader">
                      <PText weight="semi-bold">
                        {t('sharedView.firstRegistration')}
                      </PText>
                      <div className={styles.inlinePopoverContainer}>
                        <PText weight="semi-bold">
                          {t('sharedView.mileage')}
                        </PText>
                        <PPopover
                          className={styles.popover}
                          aria={{ 'aria-label': 'Additional information' }}
                        >
                          {t('sharedView.vehicleMileageInfo')}
                        </PPopover>
                      </div>
                    </PTableHeadCell>
                    <PTableHeadCell role="columnheader">
                      {!props.blockedForSalesFilter && (
                        <div className={styles.inlinePopoverContainer}>
                          <PText weight="semi-bold">
                            {t('sharedView.vehicleInitialPrice')}
                          </PText>
                          <PPopover
                            className={styles.popover}
                            aria={{
                              'aria-label':
                                'Initial price additional information',
                            }}
                          >
                            {t('sharedView.vehicleNettoPrice')}
                          </PPopover>
                        </div>
                      )}
                      <div className={styles.inlinePopoverContainer}>
                        <PText weight="semi-bold">UPE</PText>
                        <PPopover
                          className={styles.popover}
                          aria={{
                            'aria-label':
                              'Vehicle listing price additional information',
                          }}
                        >
                          {t('sharedView.vehicleGrossPrice')}
                        </PPopover>
                      </div>
                    </PTableHeadCell>
                    <PTableHeadCell role="columnheader">
                      <PText weight="semi-bold">Status</PText>
                      <PText weight="semi-bold">
                        {t('sellerView.vehicleReturnDate')}
                      </PText>
                    </PTableHeadCell>

                    <PTableHeadCell></PTableHeadCell>

                    {!props.blockedForSalesFilter && (
                      <PTableHeadCell role="columnheader">
                        <PText weight="semi-bold">
                          {t('sellerView.offeredAs')}
                        </PText>
                      </PTableHeadCell>
                    )}
                  </PTableHeadRow>
                </PTableHead>
                <PTableBody>
                  {vehicles?.map((vehicle, i) => (
                    <ReturnedVehicleRow
                      key={i}
                      vehicle={vehicle}
                      selected={isSelected(vehicle.vin)}
                      selectVehicle={selectVehicle}
                      deselectVehicle={deselectVehicle}
                    />
                  ))}
                </PTableBody>
              </PTable>
              {pageIndex !== nextPage &&
                totalVehicles - props.pageSize * pageIndex <= 0 && (
                  <PText>
                    {t('exceptions.reachedEndOfList')}
                    <br />
                    <br />
                  </PText>
                )}
            </>
          )}
          {(!nextPage || pageIndex === nextPage) && (
            <PSpinner
              size="medium"
              aria={{ 'aria-label': 'Loading page content' }}
            />
          )}
          <div className={styles.pagination} />

          {selectedVehicles?.length > 0 && (
            <div className={styles.shoppingCart}>
              <PText weight="bold">{t('sellerView.packagePreview')}</PText>
              <PText>
                {t('sellerView.vehicleSelected', {
                  count: selectedVehicles.length,
                })}
                <PPopover
                  direction="bottom"
                  description={t('sellerView.shoppingCartTooltip')}
                />
              </PText>

              <div
                className={styles.shoppingCartItemsContainer}
                data-testid="shoppingCartItemsContainer"
              >
                <PScroller>
                  {selectedVehicles.map((vehicle, i) => (
                    <div key={i} className={styles.shoppingCartItem}>
                      <div>
                        {vehicle.showroom ? (
                          <PIcon name="home" size="small"></PIcon>
                        ) : (
                          <></>
                        )}
                        {vehicle.reserved ? (
                          <PIcon name="user" size="small"></PIcon>
                        ) : (
                          <></>
                        )}
                        {vehicle.crash ? (
                          <PIcon name="wrenches" size="small"></PIcon>
                        ) : (
                          <></>
                        )}
                        {vehicle.extra_tyres ? (
                          <PIcon name="snowflake" size="small"></PIcon>
                        ) : (
                          <></>
                        )}
                      </div>
                      <img
                        className={styles.packageModalImage}
                        src={vehicle.preview || '/Car-Preview.png'}
                        alt="Vehicle"
                      />

                      <PText size="x-small" weight="bold" align="center">
                        {vehicle.derivative}
                      </PText>
                      <PText size="x-small" align="center">{vehicle.vin}</PText>
                    </div>
                  ))}
                </PScroller>
              </div>

              <PButtonGroup>
                <PButton
                  type="button"
                  aria={{ 'aria-haspopup': 'dialog' }}
                  onClick={openModal}
                  disabled={selectedVehicles.length <= 1}
                >
                  {t('sellerView.createOffer')}
                </PButton>
                <PButton
                  type="button"
                  variant="secondary"
                  onClick={deselectAllVehicles}
                >
                  {t('sellerView.cancelButton')}
                </PButton>
              </PButtonGroup>

              <PackageModal
                selectedVehicles={selectedVehicles}
                isOpen={isModalOpen}
                onDismiss={closeModal}
              ></PackageModal>
            </div>
          )}
        </>
      )}
    </div>
  )
}
