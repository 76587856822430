import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { callBackendAPI, createVehicleDetailsURL } from '../api'
import { Vehicle } from '../Models/Vehicle'
import {ErrorSerializer} from "../Models/ErrorSerializer";

export default function useVehicleDetails(
  vin?: string
): [Vehicle | null, string, string, boolean] {
  const [vehicleWithDetails, setVehicleWithDetails] = useState(null as any)
  const [isLoading, setIsLoading] = useState(false)
  const [errorHeading, setErrorHeading] = useState('')
  const [errorDescription, setErrorDescription] = useState('')

  useEffect(() => {
    setIsLoading(true)

    if (vin === null || vin === undefined) return
    let isMounted = true
    const fetchVehicleDetails = async () => {
      const vehicleDetailsURL = createVehicleDetailsURL(vin)
      try {
        const response = await callBackendAPI(vehicleDetailsURL)
        if (isMounted) {
          setVehicleWithDetails(response.data)
        }
        setIsLoading(false)
      } catch (error: unknown) {
        const axiosErrorResponse = (error as AxiosError<ErrorSerializer>).response
        const translationKey =
          axiosErrorResponse?.data.translationKey || 'unexpectedError'
        const traceId = axiosErrorResponse?.headers['x-trace-id']

        setErrorHeading(`exceptions.${translationKey}`)
        setErrorDescription(`Trace ID: ${traceId}`)

        setIsLoading(false)
      }
    }

    fetchVehicleDetails()

    return () => {
      isMounted = false
    }
  }, [vin])

  return [vehicleWithDetails, errorHeading, errorDescription, isLoading]
}
